<template>
    <div class="body">
        <!-- 导航nav -->
        <HeaderNav/>
        <!-- 返回上级页面 -->
        <div class="back">
            <img @click="goBusiness" src="../../assets/images/digitalhuman/back.png" alt="">
        </div>
        <!-- 数字人制作 -->
        <div class="digitalhu">
            <div class="digitalhu-box">
                <div  class="didbox">
                    <div class="digitalhutitle">
                        <h1>数字人制作</h1>
                        <p class="hr-solid"></p>
                    </div>
                        <p class="digitalhutxt">包括：高仿真数字人、卡通动漫风格化、二次元虚拟角色等不同类型。为企业提供各种精度的虚拟人形象定制服务包括数字人客服系统，并配以算法动作驱动功能，设计制作出多维度虚拟内容资产，打造能说、会动、可交互的智能数字人，促成虚拟 IP 深度商业挖掘，助力企业走进元宇宙时代。</p>
                    <div class="digitalhu-img">
                    <img src="../../assets/images/digitalhuman/10.png" alt="">
                    
                    </div> 
                </div>
            </div>
        </div>
        <!-- 数字人客服系统 -->
        <!-- <div class="didata">
            <div class="didata-box">
                <div class="didatadb">
                      <div class="didataimg">
                    
                    <img src="../../assets/images/digitalhuman/di3.png" alt="">
                </div>
                <div class="didatatitle">
                    <h1>数字人客服系统</h1>
                    <p class="hr-solid2"></p>
                </div>  
                    </div>
                
            </div>
        </div> -->
        <!-- 虚拟IP -->
        <div class="virtual">
            <div class="virtual-box">
                <div class="virtual-title">
                        <h1>原创虚拟 IP</h1>
                        <p class="hr-solid3"></p>
                </div>
                <div class="virtual-img">
                    <img class="di4" src="../../assets/images/digitalhuman/di4.png" alt="">
                    <div class="virtual-di">
                        <img src="../../assets/images/digitalhuman/di5.png" alt="">
                        <img src="../../assets/images/digitalhuman/di6.png" alt="">
                        <img src="../../assets/images/digitalhuman/di7.png" alt="">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import HeaderNav from "@/components/HeaderNav.vue"

export default {
    components:{
        HeaderNav,
    },
   data() {
        return{

        }
    },
    methods:{
         //跳转上级AIGC业务
      goBusiness() {
      this.$router.push({
        // 跳转到的页面路径
        path: "/business",
        // 传递的参数集合
        query: {},
      });
    },
    } 
}
</script>
<style scoped>
.body {
  width: 100%;
  height: 100%;
}
.back{
    width: 100%;
    height: 100px;
    background-color: rgba(238, 118, 55, 0.1);
}
.back img{
    width: 50px;
    height: 50px;
    margin-top: 50px;
    margin-left: 200px;
    cursor: pointer;
}
/* 数字人制作 */
.digitalhu{
  width: 100%;
  height:1000px;
  background-color: rgba(238, 118, 55, 0.1);
  position: relative;
  margin: 0 auto;
}
.digitalhu-box{
  width: 1000px;
  height: 1000px;
  position: absolute;
  /* background-color: pink; */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  
}
.didbox{
margin-top: 80px;
}
.hr-solid{
width: 800px;
margin-left: 100px;
  border: 0;
  border-top: 3px solid rgb(238, 118, 55); 
}
.digitalhutxt{
    line-height: 2;
    text-indent: 2em;
}
.digitalhutitle{
    text-align: center;
}
.digitalhu-img img{
width: 800px;
margin-left: 100px;
}
/* 数字人客服系统 */
.didata{
      width: 100%;
  height:600px;
  background-color: rgba(238, 118, 55, 0.1);
  position: relative;
  margin: 0 auto;
}
.didata-box{
   width: 1200px;
  height: 600px;
  position: absolute;
  /* background-color: pink; */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.didatadb{
    display: flex;
}
.didataimg{
    margin-left: 100px;
}
.didataimg img{
    width: 700px;
}
.hr-solid2{
width: 250px;
  border: 0;
  border-top: 3px solid rgb(238, 118, 55); 
}
.didatatitle{
    margin-left: 50px;
}
/* 虚拟 IP */
.virtual{
        width: 100%;
  height:1200px;
  background-color: rgba(238, 118, 55, 0.1);
  position: relative;
  margin: 0 auto;
}
.virtual-box{
      width: 1200px;
  height: 1200px;
  position: absolute;
  /* background-color: pink; */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.virtual-title{
    text-align: center;
}
.hr-solid3{
    width: 600px;
  border: 0;
  border-top: 3px solid rgb(238, 118, 55); 
margin-left: 300px;
}
.di4{
    width: 800px;
    margin-left: 100px;
}
.virtual-di{
margin-top: 30px;
margin-left: 100px;
}
.virtual-di img{
    width: 200px;
    margin-right: 100px;
}
.virtual-img{
    margin-left: 100px;
    margin-top: 30px;
}
</style>