<template>
    <div class="body">
        <!-- 导航nav -->
        <HeaderNav/>
        <!-- 轮播 -->
        <BannerView2 />
        <!-- 优质课程 -->
        <div class="education">
            <div class="education-box">
                <div class="education-title">
                    <h1>优质课程</h1>
                    <p class="hr-solid"></p>
                </div>
                <!-- 实战班、第二大脑 -->
                <div class="education-contion">
                    <div class="educationdata">
                     <div class="educationdatatxt">
                         <p class="educationdatap1">《室内设计 AIGC 实战班》</p>
                         <p class="hr-solided"></p>
                         <p class="educationdatap2">专为室内设计领域量身打造的课程，旨在协助设计师和装饰公司提升工作效率、加速客户获取过程，通过学习课程并学习如何利用 AIGC 来打造高效的工作流程。</p>
                     </div>
                     <div class="educationdataimg">
                        <img src="../assets/images/aigceducation/室内设计 AIGC实战班.jpg" alt=""> 
                     </div>
                    </div>
                    <div class="educationdata">
                     <div class="educationdatatxt">
                         <p class="educationdatap1">《驾驭 AI ：打造你的“第二大脑”》</p>
                         <p class="hr-solided"></p>
                         <p class="educationdatap2">当今 AI 技术已经取得了令人瞩目的进展，学习驾驭 AI ：打造你的“第二大脑”，学习最前沿的 AI 工具，帮助学习者从零开始，逐步掌握这一令人兴奋的领域。 </p>
                     </div>
                     <div class="educationdataimg">
                        <img src="../assets/images/aigceducation/驾驭AI：打造你的“第二大脑”.png" alt=""> 
                     </div>
                    </div>
                </div>
                <!-- 认知课、AI绘画 -->
                <div class="education-contion">
                    <div class="educationdata">
                     <div class="educationdatatxt">
                         <p class="educationdatap1">《每个人的 AIGC 认知课》</p>
                         <p class="hr-solided"></p>
                         <p class="educationdatap2">AIGC 领域最常用的工具知识将为你提供深入的了解，强化你的技能，使你能够利用人工智能来解决工作和生活中的需求。通过此课程学习，你将拓宽视野，增进对这一领域的认知和知识。</p>
                     </div>
                     <div class="educationdataimg">
                        <img src="../assets/images/aigceducation/每个人的AIGC认知课.jpg" alt=""> 
                     </div>
                    </div>
                    <div class="educationdata">
                     <div class="educationdatatxt">
                         <p class="educationdatap1">《 AI 绘画从小白到达人》</p>
                         <p class="hr-solided"></p>
                         <p class="educationdatap2">当今 AI 技术已经取得了令人瞩目的进展，其中之一就是 AI 绘画。我们的课程，“ AI 绘画从小白到达人”，旨在帮助学习者从零开始，逐步掌握这一令人兴奋的领域。</p>
                     </div>
                     <div class="educationdataimg">
                        <img src="../assets/images/aigceducation/AI绘画从小白到达人.jpg" alt=""> 
                     </div>
                    </div>
                </div>
                

                
            </div>
        </div>
        <!-- 优质课程介绍 -->
        <div class="introduce">
            <div class="introduce-box">
                <div class="introduce-txt">
                    <div class="introduce-conten">
                        <h2>帮助更多的人驾驭 AI </h2>
                        <p class="hr-solid2"></p>
                        <p class="aiwenan">在线教育、线下面授、模型资源库和 AI 工具平台，三位一体，确保：学得会、有跟进、能用上。</p>
                    </div>
                    <div class="introduce-conten">
                        <h2>多行业多省市的 AIGC 培训基地</h2>
                        <p class="hr-solid2"></p>
                        <div class="zhongdianbox">
                            <p class="aiwenan2">现已在广州海珠区、白云区、番禺区设置教学点，以及中山大学南昌研究院展开 AIGC 教学培训。</p>
                        </div>
                        
                    </div>
                </div>
                <div class="introduce-img">
                    <img src="../assets/images/aigceducation/9.png" alt="">
                </div>
            </div>
        </div>
        <!-- 课堂风采 -->
        <div class="show">
            <div class="show-box">
                <div class="show-title">
                    <h1>课堂风采</h1>
                    <p class="hr-solid3"></p>
                </div>
                <div class="showimg">
                    <img src="../assets/images/curriculumfc/01.jpg" alt="">
                    <img src="../assets/images/curriculumfc/02.jpg" alt="">
                    <img src="../assets/images/curriculumfc/03.jpg" alt="">
                </div>
                <div class="showimg">
                    <img src="../assets/images/curriculumfc/04.jpg" alt="">
                    <img src="../assets/images/curriculumfc/05.jpg" alt="">
                    <img src="../assets/images/curriculumfc/06.jpg" alt="">
                </div>
                <div class="showimg">
                    <img src="../assets/images/curriculumfc/07.jpg" alt="">
                    <img src="../assets/images/curriculumfc/08.jpg" alt="">
                    <img src="../assets/images/curriculumfc/09.jpg" alt="">
                </div>
                <div class="showimg">
                    <img src="../assets/images/curriculumfc/10.jpg" alt="">
                    <img src="../assets/images/curriculumfc/11.jpg" alt="">
                    <img src="../assets/images/curriculumfc/12.jpg" alt="">
                </div>
                <div class="showimg">
                    <img src="../assets/images/curriculumfc/13.jpg" alt="">
                    <img src="../assets/images/curriculumfc/14.jpg" alt="">
                    <img src="../assets/images/curriculumfc/15.jpg" alt="">
                </div>
                <div class="showimg">
                    <img src="../assets/images/curriculumfc/16.jpg" alt="">
                    <img src="../assets/images/curriculumfc/17.jpg" alt="">
                    <img src="../assets/images/curriculumfc/18.jpg" alt="">
                </div>

                
            </div>
        </div>

    </div>
</template>
<script>
import HeaderNav from "@/components/HeaderNav.vue"
import BannerView2 from "@/components/BannerView2.vue"

export default {
    name: "EducationView",
    components:{
        HeaderNav,
        BannerView2,
    },
    data(){
        return{

        };
    },

}
   
</script>

<style scoped>
.body {
  width: 100%;
  height: 100%;
}
/* 优质课程 */
.education{
  width: 100%;
  height: 2100px;
  background-color: rgba(238, 118, 55, 0.1);
  position: relative;
  margin: 0 auto;
}
.education-box{
  width: 1200px;
  height: 2100px;
  position: absolute;
  /* background-color: pink; */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.hr-solid {
  width: 600px;
  border: 0;
  border-top: 3px solid rgb(238, 118, 55);
  margin-left: 300px;
}
.education-title{
    margin-top: 100px;
}
.education-title h1{
    text-align: center;
}
.education-contion{
    width: 1200px;
    height: 830px;
    /* background-color: blueviolet; */
    display: flex;
    margin-top: 60px;
}
.educationdata{
width: 350px;
height: 800px;
/* background-color: brown; */
margin:0px 125px 0px 125px;
}
.educationdatap1{
font-size: 20px;
font-weight: 700;
text-align: center;
}
.educationdatap2{
text-indent: 2em;
line-height: 30px;
}
.educationdataimg img{
width: 350px;
}
.education-gd{
    margin-top: 80px;
    margin-left: 550px;
}
.liaojiebtn{
width: 100px;
  height: 40px;
  background-color: rgb(238, 118, 55);
  position: relative;
}
.liaojie-txt{
  color: #ffffff;
  font-size: 18px;
  font-weight: 700;
  position: absolute;
  top: 585px;
  left: 565px;
  cursor: pointer;
}
/* 优质课程介绍 */
.introduce{
  width: 100%;
  height:650px;
  background-color: rgba(238, 118, 55, 0.1);
  position: relative;
  margin: 0 auto;
}
.introduce-box{
 width: 1200px;
  height: 650px;
  position: absolute;
  /* background-color: pink; */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
}
.hr-solid2{
  width: 380px;
  border: 0;
  border-top: 3px solid rgb(238, 118, 55);
 
}
.introduce-conten{
    width: 380px;
    height: 150px;
    /* background-color: aquamarine; */
    margin-bottom: 80px;
}
.aiwenan{
    font-weight: 700;
    line-height: 1.5;
    text-indent: 2em;
}
.aiwenan2{
    text-indent: 2em;
}
.introduce-txt{
    margin-left: 80px;
    margin-top: 120px;
}
.introduce-img{
    margin-left: 80px;
}
.introduce-img img{
    width: 600px;
}
.zhongdianbox p{
font-weight: 700;
line-height: 1.5;
}
.zhongdian{
    color: rgb(238, 118, 55);;
}
/* 课堂风采 */
.show{
width: 100%;
  height:1500px;
  background-color: rgba(238, 118, 55, 0.1);
  position: relative;
  margin: 0 auto;
}
.show-box{
    width: 1020px;
  height: 1500px;
  position: absolute;
  /* background-color: pink; */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* display: flex; */
}
.show-title{
    text-align: center;
}
.hr-solid3{
  width: 400px;
  border: 0;
  border-top: 3px solid rgb(238, 118, 55);
  margin-left: 310px;
  margin-bottom: 50px;
}
.showimg{
    margin-top: 15px;
  width: 1020px;  
display: flex;  /* 设置为flex布局 */  
justify-content: space-between;  /* 图片之间平均分布 */ 
}
.showimg img{
    width: 330px;

}

</style>
