import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import waterfall from 'vue-waterfall2'

import 'element-ui/lib/theme-chalk/index.css';
// import { getCookieStore } from '@/utils/cookie';
// import SlideVerify from 'vue-monoplasty-slide-verify' // 拼图验证码


// import './plugins/lazyload';


Vue.use(waterfall)
// Vue.use(SlideVerify)
const { __APP__ } = window;
Vue.prototype.__APP__ = __APP__;
// Vue.use(fetchProxy)
Vue.use(ElementUI);


//登录路由权鉴
// router.beforeEach((to, from, next) => {
//   const token = getCookieStore(__APP__.TOKENKEY) || '';
//   if (!token && to.path !== '/login') {
//     next('/login');
//   }
//   next();
// });


Vue.config.productionTip = false
window.vue = new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
