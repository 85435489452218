<template>
    <div class="body">
        <!-- 导航nav -->
        <HeaderNav/>
        <!-- aboutus -->
    <div class="about">
      <div class="about-box">
        <div class="about-title">
          <h1>关于我们</h1>
          <p class="hr-solid"></p>
        </div>
        <div class="about-text">
          <h2 class="about-h2">公司简介</h2>
          <p>广州众易用智能科技有限公司，定位是“ AI 行业 + 应用解决方案专家”。技术涵盖生成式人工智能、三维仿真、人机交互、智能感知、数字孪生等领域。我们的产品和服务包括 AIGC 教育培训、数字人、虚拟展厅制作、VR / AR / MR 、人机交互应用、元宇宙内容生产等。</p>
          <p>我们为企业提供 AI + 应用落地的咨询服务。自主研发了“众易用 AI ”、“数字人系统”等产品。培训课程与实际应用紧密结合，赋能各行各业。我们已在动漫游戏、教育培训、室内设计、电力新能源、文博、医疗、智能检测等行业有了大量落地的成功案例。通过我们的技术和解决方案，我们帮助客户实现了创新、提升了效率，并取得了卓越的业绩。</p>
        </div>
        <!-- <div class="about-img">
            <video src="../assets/images/home/恒沙云宣传视频.mp4" loop="loop" controls="controls" muted="muted" autoplay="autoplay"></video>
          <img src="../assets/images/home/hengshayunabout.png" alt="">
        </div> -->
      </div>
      
      
    </div>
            <!-- chuangshi -->
    <div class="chuangshi">
      <div class="chuangshi-box">
        <div class="chuangshi-text">
          <div>
            <h2>创始人介绍</h2>
            <p class="hr-solid2"></p>
          </div>
          <div class="chuangshi-txt">
            <img src="../assets/images/home/caret-right.png" alt="">
            <p>黄志青博士</p>
          </div>
        </div>
        <div class="chuangshi-img">
          <img src="../assets/images/home/创始人简介.png" alt="">
        </div>
      </div>
    </div>
        <!-- 恒沙云科技理念 -->
    <div class="linian">
      <div class="linianbox">
        <div class="about-title">
            <h1>众易用智能科技理念</h1>
            <h3>Our Philosophy</h3>
            <p class="hr-solid"></p>
          <div class="liniandbox">
            <div class="freelinian">
              <div class="freelinianimg">
                <img src="../assets/images/home/caret-right.png" alt="">
              </div>
              <div>
                <p>AIGC 人工智能教育</p>
                <p>AIGC Artificial Intelligence Education</p>
              </div>
            </div>
            <div class="freelinian">
              <div class="freelinianimg">
                <img src="../assets/images/home/caret-right.png" alt="">
              </div>
              <div>
                <p>技术结合行业应用落地</p>
                <p>Technology implementation for industry solutions</p>
              </div>
            </div>
            <div class="freelinian">
              <div class="freelinianimg">
                <img src="../assets/images/home/caret-right.png" alt="">
              </div>
              <div>
                <p>需求驱动创新</p>
                <p>Demand-driven innovation</p>
              </div>
            </div>
          </div>  
          <div class="linianimg">
            <img src="../assets/images/home/大金球生态打造.png" alt="">
          </div>  
        </div>
        
      </div>
    </div>
        <!-- 4 -->
    <div class="qiyejingshen">
      <div class="jingshen-box"> 
        <div class="jingshen-txt">
          <h1>教育愿景</h1>
          <p>成为中国人工智能教育领跑者</p>
        </div>
        <div class="jingshen-txt">
          <h1>企业使命</h1>
          <p>帮助更多的人驾驭 AI </p>
        </div>
        <div class="jingshen-txt">
          <h1>企业精神</h1>
          <p>顺应趋势 拥抱不确定性</p>
        </div>
        <div class="jingshen-txt">
          <h1>企业理念</h1>
          <p>科技利民 以人为本</p>
        </div>
      </div>
    </div>
    <!-- 教学团队 -->
    <div class="team">
        <div class="team-box">
            <div class="team-title">
                <h1>团队成员</h1>
                <p class="hr-solid3"></p>
            </div>
            <div class="team-txt">
                <p>
                    成员有着文、理、艺术、教育、心理学等跨学科基因，学界、业界兼容。分别有来自国内 985 高校的顶尖专业老师，有深耕 CG 教育十几年的专业教师，有来自网易、腾讯等头部企业的专家，还有国内动画专业毕业的新锐设计师等。拥有包括发明专利在内的自主知识产权 60 余项。
                </p>
                <p>
                  我们身为国内 AIGC 应用比较早的一批人，热衷钻研新技术，是一个学习型组织。我们集合了团队在行业的应用心得，精心打造出一系列 AIGC 课程奉献给大家，希望能链接更多的年轻朋友，共同进步。
                </p>
            </div>
            <div class="fcimg3">
                    <img class="fc6" src="../assets/images/about/6.png" alt="">
                    <img class="fc7" src="../assets/images/about/7.png" alt="">
                    <img class="fc8" src="../assets/images/about/8.png" alt="">
              </div>
            <div class="fcimg1">
                    <img src="../assets/images/about/ab1.png" alt="">
                    <img src="../assets/images/about/ab4.png" alt="">
                </div>
            <div class="fcimg2">
                <img class="fc3" src="../assets/images/about/ab3.png" alt="">
                <img class="fc2" src="../assets/images/about/ab2.png" alt="">
                <img class="fc5" src="../assets/images/about/ab5.png" alt="">      
              </div>

        </div>
    </div>


    </div>
</template>
<script>
import HeaderNav from "@/components/HeaderNav.vue"

export default {
    name:"AboutView",
    components:{
        HeaderNav
    },
    data() {
        return{

        }
    },
}
</script>

<style lang="less" scoped>
.body {
  width: 100%;
  height: 100%;
}

//about
.about{
  width: 100%;
  height: 400px;
  // background-color: pink;
  position: relative;
  margin: 0 auto;
  margin-top: 50px;
}
.hr-solid {
  width: 1000px;
  border: 0;
  border-top: 3px solid rgb(238, 118, 55);
//   margin-left: 150px;
}
.about-box{
  width: 1000px;
  height: 400px;
  position: absolute;
  // background-color: antiquewhite;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.about-title{
  text-align: center;
}
.about-h2{
  text-align: center;
}
.about-text{
  line-height: 2;
}
.about-text p{
  text-indent: 2em;
}
.about-img video{
  width: 1000px;
  margin-top: 50px;
}
//恒沙云科技理念
.linian{
  width: 100%;
  height: 750px;
  position: relative;
  margin: 0 auto;
  // background-color: antiquewhite;
}
.linianbox{
  width: 1000px;
  height: 750px;
  position: absolute;
  // background-color: rgb(121, 77, 20);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
}
.linianimg img{
  width: 800px;
}
.freelinian{
  display: flex;
}
.liniandbox{
  display: flex;
}
.freelinian img{
  width: 40px;
  height: 40px;
}
.freelinianimg img{
  margin-top: 25px;
}
// 4
.qiyejingshen{
  background-color:rgba(238, 118, 55, 0.1);
  width: 100%;
  height: 200px;
  position: relative;
  margin: 0 auto;
}
.jingshen-box{
  position: absolute;
  // background-color: rgb(121, 77, 20);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 1200px;
  height: 200px;
  display: flex;
}
.jingshen-txt{
  text-align: center;
  line-height: 20px;
  margin-top: 50px;
  margin-left: 100px;
}
.jingshen-txt h1{
  color:rgb(238, 118, 55) ;
}
//chuangshi
.hr-solid2 {
  width: 130px;
  border: 0;
  border-top: 3px solid rgb(238, 118, 55);
  // margin-left: 150px;
}
.chuangshi{
  width: 100%;
  height: 650px;
  position: relative;
  margin: 0 auto;
  // background-color: antiquewhite;
}
.chuangshi-box{
  width: 1000px;
  height: 650px;
  position: absolute;
  // background-color: rgb(121, 77, 20);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
}
.chuangshi-txt{
  display: flex;
}
.chuangshi-txt p{
  margin-top: 10px;
}
.chuangshi-txt img{
  width: 40px;
  height: 40px;
}
.chuangshi-img img{
  width: 800px;
  margin-left: 70px;
    margin-top: 100px;
}
.chuangshi-text{
  margin-top: 100px;
}
// 教学团队
.team{
    width: 100%;
  height: 1750px;
  position: relative;
  margin: 0 auto;
//   background-color: antiquewhite;
}
.team-box{
    width: 1000px;
  height: 1750px;
  position: absolute;
//   background-color: rgb(121, 77, 20);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.hr-solid3{
    width: 1000px;
  border: 0;
  border-top: 3px solid rgb(238, 118, 55);
}
.team-title{
    text-align: center;
    margin-top: 80px;
}
.team-txt{
    width: 1000px;
    // margin-left: 25px;
    text-indent: 2em;
    line-height: 2;
    font-size: 18px;
    margin-top: 50px;
}
.fcimg1 img{
    width: 490px;
    height: 250px;
    margin-top: 10px;
    margin-right: 10px;
}
.fcimg2 img{
margin-right: 10px;
margin-top: 10px;
}
.fc3{
  width: 470px;
  height: 300px;
}
.fc2{
  width: 200px;
  height: 300px;
}
.fc5{
  width: 300px;
  height: 300px;
}
.fcimg3 img{
  margin-right: 10px;
margin-top: 40px;
}
.fc6{
  width: 200px;
  height: 250px;
}
.fc7{
  width: 420px;
  height: 250px;
}
.fc8{
  width: 350px;
  height: 250px;
}
</style>
