export default{
    namespaced:true,
    state: {
        //拼图验证
        isshowVerify:false
    },
    getters: {
    },
    mutations: {
        chanisshowVerify(state,payload){
            state.isshowVerify = payload
        }
    },
    actions: {
    },
}