<template>
  <!-- nav(封装) -->
  <div class="nav">
    <!-- logo -->
    <div class="zyylogo">
      <a href="#"><img src="../assets/images/zyyailogo.png" alt="" /></a>
    </div>
    <!-- 资讯ul/li列表 disabled - background-color="#333333" @select="handleSelect"-->
    <div class="text">
      <el-menu
        :default-active="activeIndex"
        class="menu-nav"
        mode="horizontal"
        text-color="#000000"
        active-text-color="#000000"
        background-color="#ffffff"
      >
        <el-menu-item index="1" @click="homefrom()">首页</el-menu-item>
        <el-menu-item index="2" @click="toEducationView()">AIGC教育</el-menu-item>
        <!-- <el-submenu index="2">
              <template slot="title">课程</template>
              <el-menu-item index="2-1" @click="msg">chatGPT课程</el-menu-item>
              <el-menu-item index="2-2" @click="msg">SD课程</el-menu-item>
              <el-menu-item index="2-3" @click="msg">MJ课程</el-menu-item>
              <el-menu-item index="2-4" @click="msg">数字人课程</el-menu-item>
              <el-menu-item index="2-5" @click="msg">AIGC技能认证系列课程</el-menu-item>
              <el-menu-item index="2-6" @click="msg">行业定制AIGC方案课程</el-menu-item>
            </el-submenu> -->
        <!-- <el-submenu index="3">
          <template slot="title">素材库</template>
          <el-menu-item index="3-1" @click="msg">图片</el-menu-item>
          <el-menu-item index="3-2" @click="msg">视频</el-menu-item>
          <el-menu-item index="3-3" @click="msg">模型</el-menu-item>
        </el-submenu> -->
        <el-menu-item index="3" @click="toBusiness()">AIGC业务</el-menu-item>
        <el-menu-item index="4" @click="toZyyai()">众易用AI</el-menu-item>
        <!-- <el-submenu index="4">
          <template slot="title">活动</template>
          <el-menu-item index="3-1" @click="msg">月度设计大赛</el-menu-item>
          <el-menu-item index="3-2" @click="msg">专题活动</el-menu-item>
          <el-menu-item index="3-3" @click="msg">线下活动资讯</el-menu-item>
        </el-submenu> -->
        <el-menu-item index="5" @click="toHengshaguan()">元宇宙</el-menu-item>
        <el-menu-item index="6" @click="toAbout()">关于我们</el-menu-item>
        <!-- <el-menu-item index="7" @click="toOrder()">接订单</el-menu-item> -->
      </el-menu>
    </div>
    <!-- 搜索引擎 -->
    <div class="search">
      <el-input
        type="text"
        v-model="input"
        placeholder="search"
        prefix-icon="el-icon-search"
      />
    </div>

    
  </div>
</template>

<script>
// import { mapMutations, mapState } from "vuex";
// import { DengluOutAPI } from "@/request/api.js";

export default {
  data() {
    return {
      activeIndex: "1",
      user_name: "",
      session: null,
      input: "",
    };
  },
  computed: {
    // ...mapState({
    //   isLogined: (state) => state.loginStatus.isLogined,
    // }),
  },
  methods: {
    // ...mapMutations({
    //   chanisshowLogin: "showLogin/chanisshowLogin",
    //   chanIsLogined: "loginStatus/chanIsLogined",
    // }),
    //退出登录
    // logout() {
    //   let res = DengluOutAPI({
    //     cmd: "user_logout",
    //     prm: {
    //       user_name: this.user_name,
    //     },
    //   }).then(() => {
    //     // 清除本地存储的用户信息
    //     localStorage.removeItem("session", res.data.session);
    //     // 其他退出登录成功后的处理逻辑
    //     this.chanIsLogined(false);
    //   });
    // },
    // logOut() {
    //   //1.删除session中的数据
    //   window.localStorage.clear("session");
    //   //2.用户访问登录页面
    //   this.$router.push("/home");
    // },
    // handleCommand(command){
    //   if(command === 'cancel'){
    //     // 清除Cookie中的token数据 PHPSESSID
    //     // Window.localStorage.remove("session");
    //     window.localStorage.clear("session");
    //     // 登出后跳转登录页
    //     this.$router.push('/home')
    //   }
    // },
    //敬请期待
    msg() {
      this.$message({
        message: "暂未开放，敬请期待",
        type: "warning",
        duration: 800,
      });
    },
    //请先登录
    // dan() {
    //   this.$message.error("请先登录");
    // },
    //缺失验证中 handleSelect (key,keyPath)
    // handleSelect(key, keyPath) {
    //   console.log(key, keyPath);
    // },

    // 回首页
    homefrom() {
      this.$router.push({
        // 跳转到的页面路径
        path: "/",
        // 传递的参数集合
        query: {},
      });
    },
    //跳转AIGC教育页面 , "_blank"
    // let EducationView = this.$router.resolve
    toEducationView() {
      this.$router.push({
        // 跳转到的页面路径
        path: "/education",
        // 传递的参数集合
        query: {},
      });
      // window.open(EducationView.href);
    },

    //跳AIGC业务
    toBusiness() {
      this.$router.push({
        // 跳转到的页面路径
        path: "/business",
        // 传递的参数集合
        query: {},
      });
      // let BusinessView = this.$router.resolve({
      //   // 跳转到的页面路径
      //   path: "/business",
      //   // 传递的参数集合
      //   query: {},
      // });
      // window.open(BusinessView.href, "_blank");
    },
    //跳关于我们
    toAbout() {
      this.$router.push({
        // 跳转到的页面路径
        path: "/about",
        // 传递的参数集合
        query: {},
      });
      // let OrderForm = this.$router.resolve({
      //   // 跳转到的页面路径
      //   path: "/order",
      //   // 传递的参数集合
      //   query: {},
      // });
      // window.open(OrderForm.href, "_blank");
    },
    //跳恒沙数字馆页面
    toHengshaguan() {
      window.open("http://show.hengshadigit.com/home","_blank")
      // this.$router.push({
      //   // 跳转到的页面路径
      //   path: "http://show.hengshadigit.com/home",
      //   // 传递的参数集合
      //   query: {},
      // });
      // window.open(EducationView.href);
    },
    //跳众易用 AI
    toZyyai() {
      this.$router.push({
        // 跳转到的页面路径
        path: "/zyyaiView",
        // 传递的参数集合
        query: {},
      });
    },
    //跳素材库页面- 旧
    toMaterial() {
      let MaterLib = this.$router.resolve({
        // 跳转到的页面路径
        path: "/materiallibrary",
        // 传递的参数集合
        query: {},
      });
      window.open(MaterLib.href, "_blank");
    },
  },
};
</script>

<style>
/* header */
.nav {
  /* width: 1920px; */
  width: 100%;
  height: 80px;
  background-color: #ffffff;
  position: relative;
}
.zyylogo img{
  width: 200px;
  height: 55px;
  top: 15px;
  /* background-color: yellow; */
  left: 150px;
  /* display: inline-block; */
  position: absolute;
}

.text {
  /* width: 250px; */
  height: 20px;
  top: 15px;
  /* background-color: rebeccapurple; */
  left: 450px;
  /* display: inline-block; */
  position: absolute;
}
/* 去除白线 */
.el-menu.el-menu--horizontal {
  border: none;
}
.el-menu--horizontal > .el-menu-item {
  border-bottom: none;
}
.el-menu--horizontal > .el-menu-item.is-active {
  border-bottom: none;
}

/* 隐藏下拉icon */
.el-submenu.is-opened > .el-submenu__title .el-submenu__icon-arrow {
  display: none;
}
.el-submenu > .el-submenu__title .el-submenu__icon-arrow {
  display: none;
}

/* 搜索 */
.nav .search {
  width: 350px;
  height: 46px;
  top: 17px;
  /* background-color: rebeccapurple; */
  left: 1150px;
  display: inline-block;
  display: flex;
  position: absolute;
}
.nav .search input {
  flex-grow: 1;
  height: 46px;
  line-height: 2px;
  text-align: center;
  outline: none;
  font-size: 15px;
  border-radius: 40px;
  border: 0.5px solid #bebebe;
}

.login-infor {
  width: 150px;
  height: 40px;
  margin-top: 20px;
  /* background-color: rgb(221, 184, 115); */
  float: right;
}
.login-btn {
  width: 90px;
  height: 40px;
  background-color: #aefbff;
  cursor: pointer;
  color: #333333;
  border-radius: 8px;
  /* align-content: center; */
  /* justify-content: center; */
  /* vertical-align:middle */
  text-align: center;
  line-height: 40px;
  display: inline-block;
}
.nav .login-infor .infor {
  margin-left: 10px;
  /* line-height: 40px; */
  /* margin-top: -10px; */
  /* padding-top: 10px; */
  /* background-color: rgb(117, 163, 103); */
  display: inline-block;
}
.user {
  width: 50px;
  height: 40px;
  /* margin-top: 20px; */
  cursor: pointer;
  /* background-color: rgb(221, 184, 115); */
  text-align: center;
  line-height: 40px;
  display: inline-block;
}
</style>