var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"body"},[_c('HeaderNav'),_c('div',{staticClass:"back"},[_c('img',{attrs:{"src":require("../../assets/images/digitalhuman/back.png"),"alt":""},on:{"click":_vm.goBusiness}})]),_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"digitalhu"},[_c('div',{staticClass:"digitalhu-box"},[_c('div',{staticClass:"didbox"},[_c('div',{staticClass:"digitalhu-text"},[_c('div',{staticClass:"digitalhutitle"},[_c('h1',[_vm._v("实体展馆数字化")]),_c('p',{staticClass:"hr-solid4"})]),_c('div',{staticClass:"digitalhu-txt"},[_c('p',[_vm._v(" 在传统展馆的基础上，添加各类声光电、多媒体、AR、VR 等人机交互、沉浸式的展览形式，为展览增添内涵和吸引理，使观感体验更加富于科技感和想象力。 ")])])]),_c('div',{staticClass:"digitalhuimg1txt"},[_c('div',{staticClass:"digitalhuimg1"},[_c('div',{staticClass:"digitalhu-img"},[_c('img',{attrs:{"src":require("../../assets/images/digitalhuicif/13.png"),"alt":""}}),_c('p',[_vm._v("“智电未来”创新基地展厅升级方案")])]),_c('div',{staticClass:"digitalhu-img"},[_c('img',{attrs:{"src":require("../../assets/images/digitalhuicif/12.png"),"alt":""}}),_c('p',[_vm._v("“智电未来”创新基地展厅升级方案")])])]),_c('div',{staticClass:"digitalhuimg2"},[_c('div',{staticClass:"digitalhu-img"},[_c('img',{attrs:{"src":require("../../assets/images/digitalhuicif/icif1.png"),"alt":""}}),_c('p',[_vm._v("大屏龙舟游戏")])])])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"didata"},[_c('div',{staticClass:"didata-box"},[_c('div',{staticClass:"didatabb1"},[_c('div',{staticClass:"didatatitle"},[_c('h1',[_vm._v("虚拟数字展厅")]),_c('p',{staticClass:"hr-solid2"}),_c('div',{staticClass:"didata-txt"},[_c('p',[_vm._v(" 使用全景技术、全三维仿真技术，结合创意和内容生产，突破实体空间局限，搭建可延展可交互的虚拟空间，实现沉浸式、手机移动式、终端交互式的等跨媒体互动展厅。 ")])])]),_c('div',{staticClass:"didataimg2"},[_c('img',{attrs:{"src":require("../../assets/images/digitalhuicif/15.png"),"alt":""}})])]),_c('div',{staticClass:"didatadb2"},[_c('div',{staticClass:"didataimg"},[_c('img',{attrs:{"src":require("../../assets/images/digitalhuicif/icif2.png"),"alt":""}}),_c('p',[_vm._v("第 16 届中国（深圳）")]),_c('p',[_vm._v("国际文博产业博览交易会（云上文博会）")])]),_c('div',{staticClass:"didataimg"},[_c('img',{attrs:{"src":require("../../assets/images/digitalhuicif/14.png"),"alt":""}}),_c('p',[_vm._v("虹桥家具展厅")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"virtual"},[_c('div',{staticClass:"virtual-box"},[_c('div',{staticClass:"virtual-title"},[_c('h1',[_vm._v("文博藏品数字化")]),_c('p',{staticClass:"hr-solid3"})]),_c('div',{staticClass:"virtual-txt"},[_c('p',[_vm._v(" 包括文物高精度三维建模、文物 AR 动画活化、互动式数字多宝阁、虚拟展厅、移动端藏品交互等多种形式。合作单位包括广东省工艺美术珍品馆、广东民间工艺博物馆（陈家祠）、广东海上丝绸之路博物馆（南海一号）、顺德博物馆、广东崇正拍卖行等。 ")])]),_c('div',{staticClass:"virtual-img"},[_c('div',{staticClass:"virtual-di"},[_c('img',{attrs:{"src":require("../../assets/images/digitalhuicif/icif3.png"),"alt":""}}),_c('p',[_vm._v("潮汕木雕交互展示")])]),_c('div',{staticClass:"virtual-di"},[_c('img',{attrs:{"src":require("../../assets/images/digitalhuicif/icif4.png"),"alt":""}}),_c('p',[_vm._v("陶瓷大师作品建模")])]),_c('div',{staticClass:"virtual-di"},[_c('img',{attrs:{"src":require("../../assets/images/digitalhuicif/icif5.png"),"alt":""}}),_c('p',[_vm._v("九龙戏珠 AR 交互")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"brand"},[_c('div',{staticClass:"brand-box"},[_c('div',{staticClass:"brandbox"},[_c('div',{staticClass:"brand-text"},[_c('div',{staticClass:"brandtitle"},[_c('h1',[_vm._v("文创品牌数字衍生设计")]),_c('p',{staticClass:"hr-solid"})]),_c('div',{staticClass:"brand-txt"},[_c('p',[_vm._v(" 结合地域文化、资源特色、潮流趋势等元素，从视觉形象、品牌体系、文创产品输出等多方面提供设计和传播服务。 ")])])]),_c('div',{staticClass:"brand-img"},[_c('div',{staticClass:"brand-img3"},[_c('div',[_c('img',{attrs:{"src":require("../../assets/images/digitalhuicif/icif6.png"),"alt":""}}),_c('p',[_vm._v("顺德故事马克杯")])]),_c('div',[_c('img',{attrs:{"src":require("../../assets/images/digitalhuicif/icif7.png"),"alt":""}}),_c('p',[_vm._v("顺德故事环保袋")])])]),_c('div',{staticClass:"brand-img2"},[_c('img',{attrs:{"src":require("../../assets/images/digitalhuicif/icif8.png"),"alt":""}}),_c('p',[_vm._v("顺德故事文创产品APP")])])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"jianzhu"},[_c('div',{staticClass:"jianzhu-box"},[_c('div',{staticClass:"jianzhudbox"},[_c('div',{staticClass:"jianzhu-text"},[_c('div',{staticClass:"jianzhutitle"},[_c('h1',[_vm._v("建筑数字化仿真")]),_c('p',{staticClass:"hr-solid"})])]),_c('div',{staticClass:"jianzhu-img"},[_c('div',{staticClass:"jianzhu-img3"},[_c('div',{staticClass:"jianzhuimg1"},[_c('img',{attrs:{"src":require("../../assets/images/digitalhuicif/16-1.png"),"alt":""}}),_c('p',[_vm._v("万科里商业区")])]),_c('div',{staticClass:"jianzhuimg2"},[_c('img',{attrs:{"src":require("../../assets/images/digitalhuicif/17-1.png"),"alt":""}}),_c('p',[_vm._v("中大历史建筑")])]),_c('div',{staticClass:"jianzhuimg3"},[_c('img',{attrs:{"src":require("../../assets/images/digitalhuicif/18-1.png"),"alt":""}}),_c('p',[_vm._v("古村祠堂")])])])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"dahuanjing"},[_c('div',{staticClass:"dahuanjing-box"},[_c('div',{staticClass:"dahuanjingdbox"},[_c('div',{staticClass:"dahuanjing-text"},[_c('div',{staticClass:"dahuanjingtitle"},[_c('h1',[_vm._v("大环境数字化")]),_c('p',{staticClass:"hr-solid"})]),_c('p',[_vm._v(" 通过对区域环境的大面积扫描、拍照、测量，用数字化手段还原环境的整体三维模型。适用于影视动画、能源交通、地方文旅等项目。 ")])]),_c('div',{staticClass:"dahuanjing-img"},[_c('div',{staticClass:"dahuanjing-img3"},[_c('div',{staticClass:"dahuanjingimg1"},[_c('img',{attrs:{"src":require("../../assets/images/digitalhuicif/19-1.png"),"alt":""}}),_c('p',[_vm._v("无人机测绘（中山大学）")])]),_c('div',{staticClass:"dahuanjingimg2"},[_c('img',{attrs:{"src":require("../../assets/images/digitalhuicif/20-1.png"),"alt":""}}),_c('p',[_vm._v("智慧航道沙盘、沙田的开发过程、智慧航道船闸系统可视化")])])])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"chondian"},[_c('div',{staticClass:"chondian-box"},[_c('div',{staticClass:"chondiandbox"},[_c('div',{staticClass:"chondian-text"},[_c('div',{staticClass:"chondiantitle"},[_c('h1',[_vm._v("充电桩 AR 系统")]),_c('p',{staticClass:"hr-solid"})]),_c('p',[_vm._v(" 全国首个充电桩博览馆-KGE 充电桩博览馆，用 AR 技术互动式展示充电桩产品特性，立体化传播用户体验佳。 ")])]),_c('div',{staticClass:"chondian-img"},[_c('div',{staticClass:"chondian-img3"},[_c('div',{staticClass:"chondianimg1"},[_c('img',{attrs:{"src":require("../../assets/images/digitalhuicif/21-1.png"),"alt":""}})])])])])])])
}]

export { render, staticRenderFns }