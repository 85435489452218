<template>
  <!-- 页脚 -->
    <div class="foot">
      <div class="foot-ba">
        <div class="foot-text">
        <!-- <p @click="toAbout()">关于AI-Seed</p>
        <p @click="openPdf1">隐私政策</p>
        <p @click="openPdf2">用户协议</p>
        <p @click="openPdf3">免责声明</p>
        <p @click="openPdf4">生成式人工智能服务管理暂行办法</p> -->
        <!-- <p @click="toContact()">联系我们</p> -->
            <div class="foot-le">
              <p class="lep1">广州众易用智能科技有限公司</p>
              <p>微信公众号 / 视频号 : 众易用AI</p>
              <p>电话 : 020-83707081 19928340470 13030195254</p>
              <p>邮箱 : info@hengshayun.com</p>
              <p>地址 : 广州市海珠区新港西路135号大院西区705号楼中大科技园B座自编号909A,909B,909C室</p>
              <div class="mtbox">
                <div class="mt1">
                  <img src="../assets/images/foot/恒沙云科技公众号.png" alt="">
                  <p class="mt1p">众易用AI公众号</p>
                </div>
                <div class="mt1">
                  <img src="../assets/images/foot/恒沙云科技视频号.png" alt="">
                  <p class="mt1p">众易用AI视频号</p>
                </div>
                
              </div>
            </div>

            <div class="foot-logo">
              <div class="footlogoimg">
                <img src="../assets/images/foot/Logo2.png" alt=""> 
              </div>
              <p class="foot-logop">AI+ 行业应用解决方案服务商</p>
              <div class="foot-txt">
              <p class="foottitle">热门频道</p>
              <p class="footpp" @click="toAigcyw">AIGC业务</p>
              <p class="footpp" @click="toAigcjy">AIGC教育</p>
              <p class="footpp" @click="toZyyai">众易用AI</p>
              <p class="footpp" @click="toYyz">元宇宙</p>
          </div>
          <div class="foot-txt">
              <p class="foottitle">关于我们</p>
              <p class="footpp" @click="toAbout">团队介绍</p>
              <p class="footpp" @click="toAbout">加入我们</p>
              <p class="footpp" @click="toAbout">联系我们</p>
          </div>
            </div>
            
          
        </div>
        <div class="banquan">
         <p>
          版权所有 广州众易用智能科技有限公司 <br />
          Copyright @ 2016-2024  All Rights Reserved.
          <a class="beian" href="https://beian.miit.gov.cn/" target="_blank"
            >粤ICP备2023136275号-1</a
          >
          <!-- <a>
          <el-link type="success" @click="openPdf5" style="color: #aefbff,text-decoration:none"
            >增值电信业务经营许可证</el-link>
         </a>
         <a>
          <el-link type="success" @click="openPdf6" style="color: #aefbff,text-decoration:none"
            >广播电视节目制作经营许可证（粤）字第 06059 号</el-link>
         </a> -->
        </p> 
        </div>
        
      </div>
    </div>

</template>

<script>
export default {
    data(){
        return{

        }
    },
    methods:{

      //跳转 aigc业务
    toAigcyw() {
      this.$router.push({
        // 跳转到的页面路径
        path: "/business",
        // 传递的参数集合
        query: {},
      });
      window.scrollTo({
        top: 0,
        // behavior: 'smooth' 
        });
    },
      //跳转 aigc教育
    toAigcjy() {
      this.$router.push({
        // 跳转到的页面路径
        path: "/education",
        // 传递的参数集合
        query: {},
      });
      window.scrollTo({
        top: 0,
        // behavior: 'smooth' 
        });
    },
      //跳转 众易用AI 
    toZyyai() {
      this.$router.push({
        // 跳转到的页面路径
        path: "/zyyaiView",
        // 传递的参数集合
        query: {},
      });
      window.scrollTo({
        top: 0,
        // behavior: 'smooth' 
        });
    },
      //跳转 元宇宙 
    toYyz() {
      window.open("http://show.hengshadigit.com/home","_blank")
    },
    //跳转联系我们页面
    toAbout() {
      this.$router.push({
        // 跳转到的页面路径
        path: "/about",
        // 传递的参数集合
        query: {},
      });
      window.scrollTo({
        top: 0,
        // behavior: 'smooth' 
        });
    },
    //协议
    // openPdf1() {
    //   window.open("./static/AI-SEED隐私协议.pdf");
    // },
    // openPdf2() {
    //   window.open("./static/AI-SEED用户协议.pdf");
    // },
    // openPdf3() {
    //   window.open("./static/免责声明.pdf");
    // },
    // openPdf4() {
    //   window.open("./static/生成式人工智能服务管理暂行办法.pdf");
    // },
    // openPdf5() {
    //   window.open("./static/增值电信业务经营许可证.pdf");
    // },
    // openPdf6() {
    //   window.open("./static/广播电视节目制作经营许可证.pdf");
    // },
    }
}
</script>

<style >
/* 页脚 */
.foot {
  /* width: 1920px; */
  width: 100%;
  height: 520px;
  color: #ffffff;
  position: relative;
  z-index: 999;
  display: inline-block;
  /* background-color: #000000; */
  background-color: rgb(0, 0, 0 );
}
.foot-ba{
  width: 1000px;
  height: 450px;
  position: absolute;
  /* background-color: pink; */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.foot-logo{
  width: 420px;
}
/* .foot-logo img{
  width: 300px;
  margin-left: 45px;
} */
.foot-logop{
  font-size: 28px;
  font-weight: 700;
  color: #cac7c8;
  text-align: center;
}
.foot-text{
display: flex;
margin-top: 15px;
justify-content: space-between;
}
.foot-wz{
  /* margin-left: 500px; */
  display: flex;
  
}
.foot-txt{
  width: 1000px;
  height: 40px;
  /* line-height: 80px; */
  /* margin-left: 50px; */
  display: flex;
}
.footpp{
    cursor: pointer;
    margin-right: 20px;
    font-size: 16px;
    color: #ffffff;
}
.foottitle{
  font-weight: 700;
  margin-right: 20px;
  /* font-size: 20px; */
}
.banquan{
  margin-top: 15px;
  text-align: center;
}
.banquan a{
  margin-right: 20px;
  font-size: 15px;
  
}
.beian{
  text-decoration:none;
  color: #fff;
}
.foot-solid-box p{
  width: 1400px;
text-align: center;
}
.foot-solid{
  
  width: 1400px;
  border: 1px solid #aefbff;
}
.lep1{
  font-size: 24px;
  font-weight: 700;
}
.mtbox{
width: 370px;
display: flex;
}
.mt1 img{
width: 100px;
}
.mt1{
  margin-right: 35px;
}
.mt1p{
  font-size: 12px;
}
.footlogoimg img{
  width: 180px;
  height: 150px;
  margin-left: 125px;
}
</style>