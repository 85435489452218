<template>
    <div class="body">
        <!-- 导航nav -->
        <HeaderNav/>
                <!-- modele -->
    <div class="model">
      <div class="model-box">
        <div class="model-concent">
          <div  class="model-text">
            <img src="../assets/images/model/model_2.png" alt="">
            <p>室内设计</p>
          </div>
          <div  class="model-text">
            <img src="../assets/images/model/model_5.png" alt="">
            <p>建筑设计</p>
          </div>
          <div class="model-text">
            <img src="../assets/images/model/model_4.png" alt="">
            <p>影视动漫</p>
          </div>
          <div class="model-text">
            <img src="../assets/images/model/model_3.png" alt="">
            <p>空间设计</p>
          </div>
          <div class="model-text">
            <img src="../assets/images/model/model_1.png" alt="">
            <p>文旅文博</p>
          </div>
        </div> 
      </div>
    </div>
        <!-- 介绍 -->
        <div class="jieshao">
            <div class="jieshaobox">
                <div class="jieshao-title">
                    <h1>关于 “ 众易用 AI 平台 ”</h1>
                    <p class="jieshaohr-solid"></p>
                </div>
                <div class="jieshao-text">
                    <h2>培训课程 + AI 工具 = 人才培养方案 = 行业解决方案</h2>
                    <p>
                        众易用智能科技自主研发的众易用 AI 平台，是一款多领域 AI 工具平台，皆在将传统工作流转变为全新的AI工作流，从而全面提升工作效率。
                    </p>
                    <p>
                       研修我们 AIGC 课程的设计师在工作实践中得到了有效的应用。 
                    </p>
                    <div class="jieshaoimg">
                      <img src="../assets/images/zyyai/1.png" alt="">
                      <img src="../assets/images/zyyai/2.png" alt="">
                    </div>
                    <p class="jsp">
                       众易用 AI 平台与我们的 AIGC 教学课程相组合，将为学员带来更加简洁、高效的使用环境，帮助他们更好地驾驭 AI 技术，拓展创意思维，实现工作上的突破与提升。 
                    </p>
                    <div class="jieshaoimg2">
                        <img src="../assets/images/zyyai/3.png" alt="">
                    </div>
                    
                </div>

            </div>
        </div>
        
    </div>
</template>

<script>
import HeaderNav from "@/components/HeaderNav.vue"
export default {
    name:"ZyyaiView",
    components:{
      HeaderNav
    },
    data() {
        return{

        }
    },
}
</script>

<style scoped>
.body{
width: 100%;
height: 100%;   
}
/* model */
.model{
  width: 100%;
  height: 200px;
   /* background-color: antiquewhite; */
  position: relative;
  margin: 0 auto;
}
.model-box{
  width: 900px;
  height: 200px;
  position: absolute;
   /* background-color: pink; */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.model-concent{
  display: flex;
}
.model-text{
  margin-left: 75px;
  margin-top: 35px;
}
.model-text p{
  margin-left: 15px;
}
/* 介绍 */
.jieshao{
  width: 100%;
  height: 1500px;
  position: relative;
  margin: 20px auto 0 auto;
  /* background-color: rgba(238, 118, 55, 0.1); */
}
.jieshaobox{
  width: 1200px;
  height: 1500px;
  position: absolute;
  /* background-color: rgb(121, 77, 20); */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.jieshaohr-solid {
  width: 1000px;
  border: 0;
  border-top: 3px solid rgb(238, 118, 55);
  margin-left: 100px;
}
.jieshao-title{
  text-align: center;
}
.jieshao-text{
    width: 1000px;
    height: 1000px;
    /* background-color: cadetblue; */
    margin-left: 100px;
}
.jieshao-text h2{
  text-align: center;
}
.jieshao-text p{
  text-indent: 2em;
}
.jieshaoimg img{
    width: 320px;
    margin-left: 120px;
}
.jieshaoimg2 img{
    margin-top: 10px;
    width: 760px;
    margin-left: 120px;
}
.jsp{
    line-height: 2;
}
</style>
