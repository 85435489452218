<template>
  <div class="body">
    <div class="top" @click="scrollToTop()" v-show="showScrollToTop">
      <i class="el-icon-caret-top"></i>
    </div>
    <!-- <div class="top2"  v-show="showScrollToTop" v-tooltip>
      <i class="el-icon-question"></i>
    </div>
    <div v-show="showImage" class="image-container">
    <img src="../assets/images/ewm/【AI小灵珑】企业微信号-样式1(2).png" alt="">
  </div> -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      showScrollToTop: false,
      showImage: false,
    };
  },
   directives: {
    tooltip: {
      bind: function(el, binding, vnode) {
        el.addEventListener("mouseover", function() {
          // 鼠标移上去时显示图片
          vnode.context.showImage = true;
        });
        el.addEventListener("mouseout", function() {
          // 鼠标移开时隐藏图片
          vnode.context.showImage = false;
        });
      }
    }
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      if (window.pageYOffset > 300) {
        this.showScrollToTop = true;
      } else {
        this.showScrollToTop = false;
      }
    },
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
    beforeDestroy() {
      window.removeEventListener("scroll", this.handleScroll);
    },
  },
};
</script>

<style  lang="less" scoped >
.body {
  width: 100%;
  background-color: #333333;
}
.top {
  width: 55px;
  height: 55px;
  position: fixed;
  z-index: 99999;
  // background-color: #333333;
  background-color: #838383;
  bottom: 50px;
  right: 30px;
  color: rgb(238, 118, 55);
  text-align: center;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  line-height: 55px;
  cursor: pointer;
  border-radius: 50px;
  box-shadow: 0 0 6px rgba(211, 211, 211, 0.12);
  // display: none;//初始隐藏
}
.top &.show {
  display: block;
}
.top2 {
  width: 55px;
  height: 55px;
  position: fixed;
  z-index: 99999;
  // background-color: #333333;
  background-color: #474747;
  bottom: 115px;
  right: 30px;
  color: #aefbff;
  text-align: center;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  line-height: 55px;
  cursor: pointer;
  border-radius: 50px;
  box-shadow: 0 0 6px rgba(211, 211, 211, 0.12);
  // display: none;//初始隐藏
  
}
.top2 &.show {
  display: block;
}
.image-container img{
  width: 135px;
  height: 136px;
  position: fixed;
  z-index: 99999;
  bottom: 115px;
  right: 95px;
}

</style>