<template>
  <div class="body">
    <!-- 导航nav -->
    <HeaderNav />
    <!-- 返回上级页面 -->
    <div class="back">
      <img
        @click="goBusiness"
        src="../../assets/images/digitalhuman/back.png"
        alt=""
      />
    </div>
    <!-- 实体展馆数字化 -->
    <div class="digitalhu">
      <div class="digitalhu-box">
        <div class="didbox">
          <div class="digitalhu-text">
            <div class="digitalhutitle">
              <h1>实体展馆数字化</h1>
              <p class="hr-solid4"></p>
            </div>
            <div class="digitalhu-txt">
              <p>
                在传统展馆的基础上，添加各类声光电、多媒体、AR、VR 等人机交互、沉浸式的展览形式，为展览增添内涵和吸引理，使观感体验更加富于科技感和想象力。
              </p>
            </div>
          </div>
          <div class="digitalhuimg1txt">
            <div class="digitalhuimg1">
              <div class="digitalhu-img">
                <img src="../../assets/images/digitalhuicif/13.png" alt="" />
                <p>“智电未来”创新基地展厅升级方案</p>
              </div>
              <div class="digitalhu-img">
                <img src="../../assets/images/digitalhuicif/12.png" alt="" />
                <p>“智电未来”创新基地展厅升级方案</p>
              </div>
            </div>
            <div class="digitalhuimg2">
              <div class="digitalhu-img">
                <img src="../../assets/images/digitalhuicif/icif1.png" alt="" />
                <p>大屏龙舟游戏</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 虚拟数字展厅 -->
    <div class="didata">
      <div class="didata-box">
        <div class="didatabb1">
          <div class="didatatitle">
            <h1>虚拟数字展厅</h1>
            <p class="hr-solid2"></p>
            <div class="didata-txt">
              <p>
                使用全景技术、全三维仿真技术，结合创意和内容生产，突破实体空间局限，搭建可延展可交互的虚拟空间，实现沉浸式、手机移动式、终端交互式的等跨媒体互动展厅。
              </p>
            </div>
          </div>

          <div class="didataimg2">
            <img src="../../assets/images/digitalhuicif/15.png" alt="" />
          </div>
        </div>
          
            <div class="didatadb2">
              <div class="didataimg">
                <img src="../../assets/images/digitalhuicif/icif2.png" alt="" />
                <p>第 16 届中国（深圳）</p>
                <p>国际文博产业博览交易会（云上文博会）</p>
              </div>
              <div class="didataimg">
                <img src="../../assets/images/digitalhuicif/14.png" alt="" />
                <p>虹桥家具展厅</p>
              </div>
            </div>
          
        
      </div>
    </div>
    <!-- 文博藏品数字化 -->
    <div class="virtual">
      <div class="virtual-box">
        <div class="virtual-title">
          <h1>文博藏品数字化</h1>
          <p class="hr-solid3"></p>
        </div>
        <div class="virtual-txt">
          <p>
            包括文物高精度三维建模、文物 AR 动画活化、互动式数字多宝阁、虚拟展厅、移动端藏品交互等多种形式。合作单位包括广东省工艺美术珍品馆、广东民间工艺博物馆（陈家祠）、广东海上丝绸之路博物馆（南海一号）、顺德博物馆、广东崇正拍卖行等。
          </p>
        </div>
        <div class="virtual-img">
          <div class="virtual-di">
            <img src="../../assets/images/digitalhuicif/icif3.png" alt="" />
            <p>潮汕木雕交互展示</p>
          </div>
          <div class="virtual-di">
            <img src="../../assets/images/digitalhuicif/icif4.png" alt="" />
            <p>陶瓷大师作品建模</p>
          </div>
          <div class="virtual-di">
            <img src="../../assets/images/digitalhuicif/icif5.png" alt="" />
            <p>九龙戏珠 AR 交互</p>
          </div>
        </div>
      </div>
    </div>
    <!-- 文创品牌数字衍生设计 -->
    <div class="brand">
      <div class="brand-box">
        <div class="brandbox">
          <div class="brand-text">
            <div class="brandtitle">
              <h1>文创品牌数字衍生设计</h1>
              <p class="hr-solid"></p>
            </div>
            <div class="brand-txt">
              <p>
                结合地域文化、资源特色、潮流趋势等元素，从视觉形象、品牌体系、文创产品输出等多方面提供设计和传播服务。
              </p>
            </div>
          </div>
          <div class="brand-img">
              <div class="brand-img3">
                <div>
                    <img src="../../assets/images/digitalhuicif/icif6.png" alt="" />
                    <p>顺德故事马克杯</p> 
                </div>
                <div>
                    <img src="../../assets/images/digitalhuicif/icif7.png" alt="" />
                    <p>顺德故事环保袋</p>  
                </div>  
              </div>
              <div class="brand-img2">
                    <img src="../../assets/images/digitalhuicif/icif8.png" alt="" />
                    <p>顺德故事文创产品APP</p>
              </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 建筑数字化仿真 -->
    <div class="jianzhu">
      <div class="jianzhu-box">
        <div class="jianzhudbox">
          <div class="jianzhu-text">
            <div class="jianzhutitle">
              <h1>建筑数字化仿真</h1>
              <p class="hr-solid"></p>
            </div>
          </div>
          <div class="jianzhu-img">
              <div class="jianzhu-img3">
                <div class="jianzhuimg1">
                    <img src="../../assets/images/digitalhuicif/16-1.png" alt="" />
                    <p>万科里商业区</p> 
                </div>
                <div class="jianzhuimg2">
                    <img src="../../assets/images/digitalhuicif/17-1.png" alt="" />
                    <p>中大历史建筑</p>  
                </div>
                <div class="jianzhuimg3">
                    <img src="../../assets/images/digitalhuicif/18-1.png" alt="" />
                    <p>古村祠堂</p>
                </div>  
              </div>
              
          </div>
        </div>
      </div>
    </div>
    <!-- 大环境数字化 -->
    <div class="dahuanjing">
      <div class="dahuanjing-box">
        <div class="dahuanjingdbox">
          <div class="dahuanjing-text">
            <div class="dahuanjingtitle">
              <h1>大环境数字化</h1>
              <p class="hr-solid"></p>
            </div>
            <p>
              通过对区域环境的大面积扫描、拍照、测量，用数字化手段还原环境的整体三维模型。适用于影视动画、能源交通、地方文旅等项目。
            </p>
          </div>
          <div class="dahuanjing-img">
              <div class="dahuanjing-img3">
                <div class="dahuanjingimg1">
                    <img src="../../assets/images/digitalhuicif/19-1.png" alt="" />
                    <p>无人机测绘（中山大学）</p> 
                </div>
                <div class="dahuanjingimg2">
                    <img src="../../assets/images/digitalhuicif/20-1.png" alt="" />
                    <p>智慧航道沙盘、沙田的开发过程、智慧航道船闸系统可视化</p>  
                </div> 
              </div>
              
          </div>
        </div>
      </div>
    </div>
    <!-- 充电桩 AR 系统 -->
    <div class="chondian">
      <div class="chondian-box">
        <div class="chondiandbox">
          <div class="chondian-text">
            <div class="chondiantitle">
              <h1>充电桩 AR 系统</h1>
              <p class="hr-solid"></p>
            </div>
            <p>
              全国首个充电桩博览馆-KGE 充电桩博览馆，用 AR 技术互动式展示充电桩产品特性，立体化传播用户体验佳。
            </p>
          </div>
          <div class="chondian-img">
              <div class="chondian-img3">
                <div class="chondianimg1">
                    <img src="../../assets/images/digitalhuicif/21-1.png" alt="" />
                </div>
              </div>
              
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import HeaderNav from "@/components/HeaderNav.vue";

export default {
  components: {
    HeaderNav,
  },
  data() {
    return {};
  },
  methods: {
    //跳转上级AIGC业务
    goBusiness() {
      this.$router.push({
        // 跳转到的页面路径
        path: "/business",
        // 传递的参数集合
        query: {},
      });
    },
  },
};
</script>
<style scoped>
.body {
  width: 100%;
  height: 100%;
}
.back {
  width: 100%;
  height: 100px;
  background-color: rgba(238, 118, 55, 0.1);
}
.back img {
  width: 50px;
  height: 50px;
  margin-top: 50px;
  margin-left: 200px;
  cursor: pointer;
}
/* 实体展馆数字化 */
.digitalhu {
  width: 100%;
  height: 700px;
  background-color: rgba(238, 118, 55, 0.1);
  position: relative;
  margin: 0 auto;
}
.digitalhu-box {
  width: 1200px;
  height: 700px;
  position: absolute;
  /* background-color: pink; */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.didbox {
  display: flex;
  margin-top: 80px;
}
.hr-solid {
  width: 250px;
  border: 0;
  border-top: 3px solid rgb(238, 118, 55);
}
.hr-solid4 {
  width: 240px;
  border: 0;
  border-top: 3px solid rgb(238, 118, 55);
}
.digitalhu-text {
  width: 350px;
}
.digitalhutitle {
  margin-left: 100px;
}
.digitalhu-txt {
  margin-left: 100px;
  line-height: 2;
  text-indent: 2em;
}
.digitalhuimg1 {
  display: flex;
}
.digitalhuimg2 {
  margin-top: 20px;
}
.digitalhuimg1txt {
  text-align: center;
}
.digitalhu-img {
  margin-left: 50px;
}
.digitalhu-img img {
  width: 350px;
  margin-left: 30px;
}
/* 虚拟数字展厅 */
.didata {
  width: 100%;
  height: 1000px;
  background-color: rgba(238, 118, 55, 0.1);
  position: relative;
  margin: 0 auto;
}
.didata-box {
  width: 1200px;
  height: 1000px;
  position: absolute;
  /* background-color: pink; */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.didatabb1 {
  display: flex;
}
.didatadb2 {
  display: flex;
  margin-top: 30px;
  margin-left: 50px;
}
.didataimg {
  margin-left: 50px;
}
.didataimg2 {
  margin-left: 100px;
}
.didataimg img {
  width: 350px;
  height: 300px;
}
.didataimg2 img {
  width: 600px;
  height: 500px;
}
.hr-solid2 {
  width: 300px;
  border: 0;
  border-top: 3px solid rgb(238, 118, 55);
}
.didatatitle {
  width: 300px;
  margin-left: 100px;
}
.didata-txt {
  line-height: 2;
  text-indent: 2em;
}
/* 文博藏品数字化 */
.virtual {
  width: 100%;
  height: 800px;
  background-color: rgba(238, 118, 55, 0.1);
  position: relative;
  margin: 0 auto;
}
.virtual-box {
  width: 1200px;
  height: 800px;
  position: absolute;
  /* background-color: pink; */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.virtual-title {
  text-align: center;
}
.hr-solid3 {
  width: 600px;
  border: 0;
  border-top: 3px solid rgb(238, 118, 55);
  margin-left: 300px;
}
.di4 {
  width: 1000px;
}
.virtual-di {
  margin-top: 30px;
}
.virtual-di img {
  width: 300px;
  margin-right: 50px;
}
.virtual-di p{
    margin-left: 90px;
}
.virtual-img {
  margin-left: 100px;
  margin-top: 80px;
  display: flex;
}
.virtual-txt {
  line-height: 2;
  text-indent: 2em;
  margin-top: 50px;
}
/* 文创品牌数字衍生设计 */
.brand {
  width: 100%;
  height: 750px;
  background-color: rgba(238, 118, 55, 0.1);
  position: relative;
  margin: 0 auto;
}
.brand-box {
  width: 1200px;
  height: 750px;
  position: absolute;
  /* background-color: pink; */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.brandbox {
  display: flex;
  margin-top: 80px;
}
.hr-solid {
  width: 350px;
  border: 0;
  border-top: 3px solid rgb(238, 118, 55);
}
.brand-text {
  width: 400px;
  margin-left: 100px;
}
.brandtitle {
  margin-left: 50px;
}
.brand-txt {
  margin-left: 50px;
  line-height: 2;
  text-indent: 2em;
}
.brand-img {
  margin-left: 50px;
}
.brand-img3 p{
  margin-left: 60px;
}
.brand-img2 p{
  margin-left: 155px;
}
.brand-img img {
  width: 200px;
  margin-left: 20px;
}
.brand-img2 img {
  width: 420px;
  margin-top: 20px;
}
.brand-img3{
  display: flex;
}
/* 建筑数字化仿真 */
.jianzhu {
  width: 100%;
  height: 350px;
  background-color: rgba(238, 118, 55, 0.1);
  position: relative;
  margin: 0 auto;
}
.jianzhu-box {
  width: 1200px;
  height: 350px;
  position: absolute;
  /* background-color: pink; */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
/* .jianzhutitle{
  margin-left: 150px;
} */
.jianzhu-img3{
  display: flex;
  text-align: center;
}

.jianzhuimg2{
  margin-left: 10px;

}
.jianzhuimg3{
  margin-left: 10px;

}
.jianzhuimg1 img{
  width: 540px;
  height: 137px;
}
.jianzhuimg2 img{
  width: 440px;
  height: 137px;
}
.jianzhuimg3 img{
  width: 200px;
  height: 137px;
}
/* 大环境数字化 */
.dahuanjing {
  width: 100%;
  height: 350px;
  background-color: rgba(238, 118, 55, 0.1);
  position: relative;
  margin: 0 auto;
}
.dahuanjing-box {
  width: 1200px;
  height: 350px;
  position: absolute;
  /* background-color: pink; */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.dahuanjing-text p{
  text-indent: 2em;
}
.dahuanjing-img3{
  display: flex;
  text-align: center;
}
.dahuanjingimg1 img{
  width: 300px;
  height: 137px;
}
.dahuanjingimg2 img{
  width: 870px;
  height: 137px;
}
.dahuanjingimg2{
  margin-left: 30px;
}
/* 充电桩AR */
.chondian {
  width: 100%;
  height: 550px;
  background-color: rgba(238, 118, 55, 0.1);
  position: relative;
  margin: 0 auto;
}
.chondian-box {
  width: 1200px;
  height: 550px;
  position: absolute;
  /* background-color: pink; */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.chondian-text p{
  text-indent: 2em;
}
.chondianimg1 img{
  width: 1000px;
}
</style>

