<template>
  <div id="app">
  
    <router-view/>
    <TopView></TopView>
    <FootView></FootView>
    
  <!-- <DengLuaiseed v-show="isshowLogin"></DengLuaiseed> -->
  </div>
</template>

<script>
// import devPixelRatio from "@/utils/devicePixelRatio.js";
// import DengLuaiseed from "@/components/DengLuaiseed.vue"
// import HeaderNav from "@/components/HeaderNav.vue"
import FootView from "@/components/FootView.vue"
import TopView from "@/components/TopView.vue"
import {mapState} from "vuex"

export default {
  name: 'app',
  // created() {
  //   new devPixelRatio().init(); // 初始化页面比例
  // },
  data(){
    return{

    }
  },
  computed:{
    ...mapState({
      isshowLogin:state=>state.showLogin.isshowLogin
    })
  },
  components:{
    // DengLuaiseed,
    FootView,
    TopView,
    // HeaderNav,
  },
  methods:{

    
  }
};

</script>

<style lang="scss">
#app {
  /* font-family: Avenir, Helvetica, Arial, sans-serif; */
  /* -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
  /* text-align: center; */
  height: 100%;

}
body{
  margin: 0;
  padding: 0;
  // max-width: 1920px;
  // width: 100%;
  //min-width: 1580px;
  background-color:#ffffff;
  
}
// .scroll-to-top {
//   width: 40px;
//   height: 40px;
//   background-color: aquamarine;
//   position: fixed;
//   bottom: 20px;   /* 距离底部的距离 */
//   right: 20px;    /* 距离右侧的距离 */
//   z-index: 9999;  /* 确保按钮位于其他元素之上 */
//   display: none;  /* 初始状态隐藏按钮 */
// }

// /* 当滚动条滚动超过一定距离时显示按钮 */
// .scroll-to-top.show {
//   display: block;
// }
</style>
