<template>
  <div class="body">
    <!-- 导航nav -->
    <HeaderNav/>
    <!-- 轮播 -->
    <BannerView />
    <!-- modele 五个类别-->
    <!-- <div class="model">
      <div class="model-box">
        <div class="model-concent">
          <div  class="model-text">
            <img src="../assets/images/model/model_1.png" alt="">
            <p>文物艺术类</p>
          </div>
          <div  class="model-text">
            <img src="../assets/images/model/model_2.png" alt="">
            <p>房地产类</p>
          </div>
          <div class="model-text">
            <img src="../assets/images/model/model_3.png" alt="">
            <p>空间社区类</p>
          </div>
          <div class="model-text">
            <img src="../assets/images/model/model_4.png" alt="">
            <p>交互设计类</p>
          </div>
          <div class="model-text">
            <img src="../assets/images/model/model_5.png" alt="">
            <p>企业文化类</p>
          </div>
        </div> 
      </div>
    </div> -->
    <!-- aboutus -->
    <div class="about">
      <div class="about-box">
        <div class="about-title">
          <h1>关于众易用智能科技</h1>
          <p class="hr-solid"></p>
        </div>
        <div class="about-text">
          <h2 class="about-h2">公司简介</h2>
          <p>广州众易用智能科技有限公司，定位是“ AI 行业 + 应用解决方案专家”。技术涵盖生成式人工智能、三维仿真、人机交互、智能感知、数字孪生等领域。我们的产品和服务包括 AIGC 教育培训、数字人、虚拟展厅制作、VR / AR / MR 、人机交互应用、元宇宙内容生产等。</p>
          <p>我们为企业提供 AI + 应用落地的咨询服务。自主研发了“众易用 AI ”、“数字人系统”等产品。培训课程与实际应用紧密结合，赋能各行各业。我们已在动漫游戏、教育培训、室内设计、电力新能源、文博、医疗、智能检测等行业有了大量落地的成功案例。通过我们的技术和解决方案，我们帮助客户实现了创新、提升了效率，并取得了卓越的业绩。</p>
        </div>
        <div class="about-img">
          <!-- <video src="../assets/images/home/恒沙云宣传视频.mp4" loop="loop" controls="controls" muted="muted" autoplay="autoplay"></video> -->
          <!-- <img src="../assets/images/home/hengshayunabout.png" alt=""> -->
        </div>
      </div>
      
      
    </div>
    <!-- chuangshi -->
    <div class="chuangshi">
      <div class="chuangshi-box">
        <div class="chuangshi-text">
          <div>
            <h2>创始人介绍</h2>
            <p class="hr-solid2"></p>
          </div>
          <div class="chuangshi-txt">
            <img src="../assets/images/home/caret-right.png" alt="">
            <p>黄志青博士</p>
          </div>
        </div>
        <div class="chuangshi-img">
          <img src="../assets/images/home/创始人简介.png" alt="">
        </div>
        
      </div>
    </div>
    <!-- 恒沙云科技理念 -->
    <div class="linian">
      <div class="linianbox">
        <div class="about-title">
            <h1>众易用智能科技理念</h1>
            <h3>Our Philosophy</h3>
            <p class="hr-solid"></p>
          <div class="liniandbox">
            <div class="freelinian">
              <div class="freelinianimg">
                <img src="../assets/images/home/caret-right.png" alt="">
              </div>
              <div>
                <p>AIGC 人工智能教育</p>
                <p>AIGC Artificial Intelligence Education</p>
              </div>
            </div>
            <div class="freelinian">
              <div class="freelinianimg">
                <img src="../assets/images/home/caret-right.png" alt="">
              </div>
              <div>
                <p>技术结合行业应用落地</p>
                <p>Technology implementation for industry solutions</p>
              </div>
            </div>
            <div class="freelinian">
              <div class="freelinianimg">
                <img src="../assets/images/home/caret-right.png" alt="">
              </div>
              <div>
                <p>需求驱动创新</p>
                <p>Demand-driven innovation</p>
              </div>
            </div>
          </div> 
          <div class="linianimg">
            <img src="../assets/images/home/大金球生态打造.png" alt="">
          </div>  
        </div>
        
      </div>
    </div>
    <!-- 4企业 -->
    <div class="qiyejingshen">
      <div class="jingshen-box"> 
        <div class="jingshen-txt">
          <h1>教育愿景</h1>
          <p>成为中国人工智能教育领跑者</p>
        </div>
        <div class="jingshen-txt">
          <h1>企业使命</h1>
          <p>帮助更多的人驾驭 AI</p>
        </div>
        <div class="jingshen-txt">
          <h1>企业精神</h1>
          <p>顺应趋势 拥抱不确定性</p>
        </div>
        <div class="jingshen-txt">
          <h1>企业理念</h1>
          <p>科技利民 以人为本</p>
        </div>
      </div>
    </div>

    <!-- AIGC职业技能培训 -->
    <div class="aigc">
      <div class="aigc-box">
        <p class="hr-solid3"></p>
        <div class="aigc-content">
         <div class="aigc-img">
          <img src="../assets/images/home/职业技能培训.png" alt="">
        </div>
        <div class="aigc-text">
          <h1>AIGC 教育培训</h1>
          <p>在线教育、线下面授、模型资源库和 AI 工具平台，三位一体</p>
          <p>确保：学得会、有跟进、能用上</p>
          <div class="liaojiebutton">
            <div class="liaojie"></div>
            <p class="liaojie-txt" @click="goaigcjy">了解更多</p>
          </div>
        </div>
        </div>
      </div>
    </div>
    <!-- AIGC业务 -->
    <div class="aigcyw">
      <div class="aigcyw-box">
        <div class="aigcyw-title">
          <h1>AIGC 业务</h1>
          <p>AIGC 是指 AI - Generated Content，人工智能生成内容。内容生产在过去经历了PGC（专业人士创建内容）、UGC（用户创建内容）、AIUGC（用户和 AI 辅助创建），而2024年人工智能技术突飞猛进，AIGC 的大潮已经到来。</p>
        </div>
        <div class="aigcyw-img">
          <div>
            <div class="aigcywimg-box1">
              <div class="aigcywb1">
                <img class="aigcywimg-tupian" src="../assets/images/home/AIGC1-1.png" alt="">
                <p class="aigcywtitle">AIGC 教育</p>
              </div>
              <div>
                <p class="aigcywtitle">AIGC 教育</p>
                <p class="aigcywpp">
                  通过融合人工智能和生成式内容技术形成的新型教育模式，随着技术的不断进步和应用领域的扩大，AIGC 教育将在未来发挥更加重要的作用，为各行各业的创新和发展带来新的机遇和挑战。
                </p> 
              </div>
            </div>

            <div class="aigcywimg-box1">
              <div class="aigcywb1">
                <img class="aigcywimg-tupian" src="../assets/images/home/AIGC2.png" alt="">
                <p class="aigcywtitle">AI 模型训练</p> 
              </div>
              <div>
                <p class="aigcywtitle">AI 模型训练</p> 
                <p class="aigcywpp">
                  AI 模型训练可以用于实际任务中，如图像识别、语音识别、自然语言处理等。通过不断的训练和优化，AI 模型可以在各种应用中发挥越来越重要的作用，推动人工智能技术的发展和应用。
                </p>
              </div>
            </div>

            <div class="aigcywimg-box1">
              <div class="aigcywb1">
                <img class="aigcywimg-tupian" src="../assets/images/home/AIGC3.png" alt="">
                <p class="aigcywtitle">数字人创建</p>
              </div>
              <div>
                <p class="aigcywtitle">数字人创建</p>
                <p class="aigcywpp">
                在数字人创建中骨骼绑定是关键步骤之一，还可以赋予数字人逼真的动作和姿态。除了视觉呈现，通过集成人工智能技术，数字人能够识别用户的语音和姿态，并作出相应的回应，这种智能交互使得数字人能够与用户建立更紧密的互动关系，从而提升用户体验。
                </p>
              </div>
            </div>
          </div>

          <div>
            <div class="aigcywimg-box1">
              <div class="aigcywb1">
                <img class="aigcywimg-tupian" src="../assets/images/home/AIGC4-1.jpeg" alt="">
                <p class="aigcywtitle">VR / AR / MR</p>
              </div>
              <div>
                <p class="aigcywtitle">VR / AR / MR</p>
                <p class="aigcywpp">
                VR（虚拟现实）、AR（增强现实）和MR（混合现实）这三种技术不断发展和融合，将为人们带来更加真实、丰富和沉浸式的数字世界体验。
                </p>
              </div>
            </div>

            <div class="aigcywimg-box1">
              <div class="aigcywb1">
                <img class="aigcywimg-tupian" src="../assets/images/home/AIGC5.png" alt="">
                <p class="aigcywtitle">行业 AI 工具</p>
              </div>
              <div>
                <p class="aigcywtitle">行业 AI 工具</p>
                <p class="aigcywpp">
                AI 工具不仅可以提高各行各业的业务效率，降低运营成本，还可以为企业带来新的商业模式和增长点。这些AI 工具利用机器学习、深度学习等先进技术，结合行业数据和业务需求，为用户提供智能化的解决方案。
                </p>
              </div>
            </div>

            <div class="aigcywimg-box1">
              <div class="aigcywb1">
                <img class="aigcywimg-tupian" src="../assets/images/home/AIGC6.png" alt="">
                <p class="aigcywtitle">数字内容生产</p>
              </div>
              <div>
                <p class="aigcywtitle">数字内容生产</p>
                <p class="aigcywpp">
                随着数字技术的不断发展和用户需求的不断变化，将创意转化为具体的数字内容具有高效性、灵活性、互动性、多样性和可量化分析等优势，能够满足大环境下用户多样化的信息需求和审美偏好，推动数字产业的快速发展。
                </p>
              </div>
              
            </div>
          </div>


        </div>
      </div>
    </div>
    <!-- 成功案例 -->
    <div class="anli">
      <div class="anli-box">
        <p class="hr-solid3"></p>
        <div class="anli-content">
          <div class="anli-text">
            <h1>成功案例</h1>
            <p>数字人</p>
            <p>数字化文博</p>
            <p>元宇宙空间</p>
            <p>......</p>
              <div class="liaojiebutton2">
                <div class="liaojie2"></div>
                <p class="liaojie-txt2" @click="goaiyw">了解更多</p>
              </div>
          </div>
          <div class="anli-imgbox">
            <div class="altxt">
              <p>数字人制作</p>
              <p>原创虚拟 IP</p>
              <p>数字人客服系统</p>
              <p>实体展馆数字化</p>
              <p>元宇宙空间</p>
              <p>虚拟数字展厅</p>
            </div>
            <div class="alimg">
              <img src="../assets/images/home/al1.png" alt="">
              <img src="../assets/images/home/al2.png" alt="">
              <img src="../assets/images/home/al3.png" alt="">
              <img src="../assets/images/home/al4.png" alt="">
              <img src="../assets/images/home/al5.png" alt="">
              <img src="../assets/images/home/al6.png" alt="">
            </div>
            
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script  >
// @ is an alias to /src
// import HelloWorld from "@/components/HelloWorld.vue";
// import DetailView from "@/views/DetailView.vue"
// import {mapMutations} from "vuex"
import HeaderNav from "@/components/HeaderNav.vue"
import BannerView from "@/components/BannerView.vue"
// import ListView from "@/components/home/ListView.vue"
// import { MaterialAPI } from "@/request/api.js"
// import { Pagination } from "element-ui";

export default {
  name: "HomeView",
  components: {
    // "el-pagination": Pagination,
    HeaderNav,
    BannerView,
    // ListView,
  },
  data() {
    return {
        modelData:[],
        reference_pic:[],
        pageindex: 1,
        pagelines: 30,
        totalcount: 0, 
    };
  },
  
  created() {
    // this.chatModel()
    
    // this.handleCurrentChange()
    

    
  },

  methods: {

    // ...mapMutations({
    //   chanisshowLogin:"showLogin/chanisshowLogin"
    // }),
    scrollToTop() {
        window.scrollTo({
        top: 0,
        // behavior: 'smooth' 
        }); 
      },
    handleCurrentChange(newpageindex){
      this.pageindex = newpageindex;
      this.chatModel();
      this.scrollToTop();
        },
    
    
    //跳转资讯页面
    toInformation() {
      let InformationView = this.$router.resolve({
        // 跳转到的页面路径
        path: "/information",
        // 传递的参数集合
        query: {},
      });
      window.open(InformationView.href, "_blank");
    },
       //跳转AIGC教育
      goaigcjy() {
      this.$router.push({
        // 跳转到的页面路径
        path: "/education",
        // 传递的参数集合
        query: {},
      });
      window.scrollTo({
        top: 0,
        // behavior: 'smooth' 
        });
    },
       //跳转AIGC业务
      goaiyw() {
      this.$router.push({
        // 跳转到的页面路径
        path: "/business",
        // 传递的参数集合
        query: {},
      });
      window.scrollTo({
        top: 0,
        // behavior: 'smooth' 
        });
    },

    msg() {
      this.$message({
        message: "暂未开放，敬请期待",
        type: "warning",
        duration: 700,
      });
    },
    dan() {
      this.$message({
        message:"请先登录",
        type: "error",
        duration: 700,
      });
    },
    
  },
};
</script>

<style lang="less" scoped>
.body {
  width: 100%;
  height: 100%;
}
// model
.model{
  width: 100%;
  height: 200px;
  // background-color: antiquewhite;
  position: relative;
  margin: 0 auto;
}
.model-box{
  width: 900px;
  height: 200px;
  position: absolute;
  // background-color: pink;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.model-concent{
  display: flex;
}
.model-text{
  margin-left: 75px;
  margin-top: 35px;
}
//about
.about{
  width: 100%;
  height: 400px;
  // background-color: pink;
  position: relative;
  margin: 0 auto;
  margin-top: 50px;
}
.hr-solid {
  width: 1000px;
  border: 0;
  border-top: 3px solid rgb(238, 118, 55);
  // margin-left: 50px;
}
.about-box{
  width: 1000px;
  height: 400px;
  position: absolute;
  // background-color: antiquewhite;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.about-title{
  text-align: center;
}
.about-h2{
  text-align: center;
}
.about-text{
  line-height: 2;
}
.about-text p{
  text-indent: 2em;
}
.about-img video{
  width: 1000px;
  margin-top: 50px;
  // margin-left: 0px;
}
// 4
.qiyejingshen{
  background-color:rgba(238, 118, 55, 0.1);
  width: 100%;
  height: 200px;
  position: relative;
  margin: 0 auto;
}
.jingshen-box{
  position: absolute;
  // background-color: rgb(121, 77, 20);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 1200px;
  height: 200px;
  display: flex;
}
.jingshen-txt{
  text-align: center;
  line-height: 20px;
  margin-top: 50px;
  margin-left: 100px;
}
.jingshen-txt h1{
  color:rgb(238, 118, 55) ;
}
//恒沙云科技理念
.linian{
  width: 100%;
  height: 750px;
  position: relative;
  margin: 0 auto;
  // background-color: antiquewhite;
}
.linianbox{
  width: 1000px;
  height: 750px;
  position: absolute;
  // background-color: rgb(121, 77, 20);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
}
.linianimg img{
  width: 800px;
}
.freelinian{
  display: flex;
}
.liniandbox{
  display: flex;
}
.freelinian img{
  width: 40px;
  height: 40px;
}
.freelinianimg img{
  margin-top: 25px;
}
//chuangshi
.hr-solid2 {
  width: 130px;
  border: 0;
  border-top: 3px solid rgb(238, 118, 55);
  // margin-left: 150px;
}
.chuangshi{
  width: 100%;
  height: 650px;
  position: relative;
  margin: 0 auto;
  // background-color: antiquewhite;
}
.chuangshi-box{
  width: 1000px;
  height: 650px;
  position: absolute;
  // background-color: rgb(121, 77, 20);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
}
.chuangshi-txt{
  display: flex;
  
}
.chuangshi-txt p{
  margin-top: 10px;
}
.chuangshi-txt img{
  width: 40px;
  height: 40px;
}
.chuangshi-img img{
  width: 800px;
  margin-left: 70px;
  margin-top: 100px;
}
.chuangshi-text{
  margin-top: 100px;
}
// aigc
.aigc{
  width: 100%;
  height: 700px;
  position: relative;
  margin: 0 auto;
  // background-color: antiquewhite;
}
.aigc-box{
  width: 1200px;
  height: 700px;
  position: absolute;
  // background-color: rgb(121, 77, 20);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.hr-solid3{
  width: 900px;
  border: 0;
  border-top: 3px solid rgb(238, 118, 55);
  margin-left: 150px;
  margin-top: 150px;
}
.aigc-content{
  display: flex;
  margin-top: 150px;
}
.aigc-img{
  margin-left: 50px;
}
.aigc-img img{
  width: 600px;
}
.aigc-text{
  margin-left: 50px;
}
.liaojiebutton{
  margin-top: 70px;
  cursor: pointer;
}
.liaojie{
  width: 100px;
  height: 40px;
  background-color: rgb(238, 118, 55);
  position: relative;
  
}
.liaojie-txt{
  color: #ffffff;
  font-size: 18px;
  position: absolute;
  top: 506px;
  left: 715px;
  font-weight: 700;
}
//AIGC业务
.aigcyw{
  width: 100%;
  height: 950px;
  position: relative;
  margin: 0 auto;
  background-color: rgba(238, 118, 55, 0.1);
}
.aigcyw-box{
  width: 1200px;
  height: 950px;
  position: absolute;
  // background-color: rgb(121, 77, 20);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.aigcyw-title{
  margin-top: 100px;
}
.aigcyw-img{
  margin-top: 50px;
  display: flex;
}
.aigcyw-title h1{
  text-align: center;
}
.aigcyw-title p{
  text-indent: 2em;
  line-height: 30px;
}
.aigcywimg-tupian{
  width: 150px;
}
.aigcywimg-box1{
  width: 550px;
  height: 200px;
  background-color: #f7f7f7;
  margin-left: 42px;
  margin-top: 15px;
  display: flex;
}
.aigcywb1{
  background-color: #ffffff;
}
.aigcywtitle{
  text-align: center;
  font-size: 18px;
  font-weight: 700;
}
.aigcywpp{
  margin-left: 10px;
  width: 380px;
  font-size: 16px;
  text-indent: 2em;
}
.aigcywimg-box1 img{
  width: 150px;
  height: 113.97px;
}
//anli
.anli{
  width: 100%;
  height: 1800px;
  position: relative;
  margin: 0 auto;
  // background-color: rgba(98, 96, 231, 0.1);
}
.anli-box{
  width: 1200px;
  height: 1800px;
  position: absolute;
  // background-color: rgb(185, 250, 223);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.anli-content{
  display: flex;
  margin-top: 100px;
}
.anli-text{
  margin-left: 100px;
}
.anli-imgbox{
  width: 700px;
  height: 1380px;
  background-color: rgba(238, 118, 55, 0.1);
  margin-left: 100px;
}
.alimg img{
  width: 600px;
  margin-bottom: 10px;
}
.alimg{
  margin-top: 30px;
  margin-left: 50px;
}
.altxt{
  position: absolute;
  top: 450px;
  left: 390px;
}
.altxt p{
    margin-bottom: 200px;
    color: #ffffff;
    font-weight: 700;
}
.liaojie2{
  width: 100px;
  height: 40px;
  background-color: rgb(238, 118, 55);
  position: relative;
}
.liaojie-txt2{
  color: #ffffff;
  font-size: 18px;
  font-weight: 700;
  position: absolute;
  top: 475px;
  left: 115px;
  cursor: pointer;
}
</style>
