export default{
    namespaced:true,
    state: {
        //登录状态
        isLogined:localStorage.getItem("session")?true:false
    },
    getters: {
    },
    mutations: {
        chanIsLogined(state,payload){
            state.isLogined = payload
        }
    },
    actions: {
    },
}