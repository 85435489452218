import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import EducationView from '../views/EducationView.vue'
import BusinessView from '../views/BusinessView.vue'
import AboutView from '../views/AboutView.vue'
import ZyyaiView from '../views/ZyyaiView.vue'
import DigitalhumanView from '../components/business/DigitalhumanView.vue'
import DigitalhuicifView from '../components/business/DigitalhuicifView.vue'

//exhibition
Vue.use(VueRouter);

const routes =[
  {
    path: '/', 
    redirect: '/home',
  },
  {
    path: '/home',
    name: 'home',
    component: HomeView,
  },
  {
    path: '/education',
    name: 'education',
    component: EducationView,
  },
  {
    path: '/business',
    name: 'business',
    component: BusinessView,
  },
  {
    path: '/zyyaiView',
    name: 'zyyaiView',
    component: ZyyaiView,
  },
  {
    path: '/about',
    name: 'about',
    component: AboutView,
  },
  {
    path: '/digitalhuman',
    name: 'digitalhuman',
    component: DigitalhumanView,
  },
  {
    path: '/digitalhuicif',
    name: 'digitalhuicif',
    component: DigitalhuicifView,
  },

  { path: "*", redirect: "/404", hidden: true }
]

// export default  new VueRouter({
//   mode: 'history',
//   base: process.env.BASE_URL,
//   routes:[
    
//   ]
// })

// 解决vue-router在3.0版本以上重复报错问题
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const router = new VueRouter({
  routes
})

export default router
