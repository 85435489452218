import Vue from 'vue'
import Vuex from 'vuex'
import showLogin from './showLogin'
import loginStatus from './loginStatus'
import showVerify from './showVerify'

Vue.use(Vuex)

export default new Vuex.Store({
  
  modules: {
    showLogin,
    loginStatus,
    showVerify
  }
})
