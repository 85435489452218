<template>
    <div class="body">
        <!-- 导航nav -->
        <HeaderNav/>
            <!-- AIGC业务 -->
    <!-- <div class="aigcyw">
      <div class="aigcyw-box">
        <div class="aigcyw-title">
          <h1>AIGC 业务</h1>
          <p>AIGC 是指 AI - Generated Content，人工智能生成内容。内容生产在过去经历了PGC（专业人士创建内容）、UGC（用户创建内容）、AIUGC（用户和 AI 辅助创建），而2024年人工智能技术突飞猛进，AIGC 的大潮已经到来。</p>
        </div>
        <div class="aigcyw-img">
          <div class="aigcywimage1">
            <img src="../assets/images/business/11.png" alt="">
          </div>
          <div class="aigcywbigbox">
            <div class="aigcywimgdb1">
              <div class="aigcywimg-box1">
                <img class="aigcywimg-tupian" src="../assets/images/home/AIGC1-1.png" alt="">
                <p>AIGC 教育</p>
              </div>
              <div class="aigcywimg-box1">
                <img class="aigcywimg-tupian" src="../assets/images/home/AIGC2.png" alt="">
                <p>AI 模型训练</p>
              </div>
              <div class="aigcywimg-box1">
                <img class="aigcywimg-tupian" src="../assets/images/home/AIGC3.png" alt="">
                <p>数字人创建</p>
              </div>
            </div>
            <div class="aigcywimgdb1">
              <div class="aigcywimg-box1">
                <img class="aigcywimg-tupian" src="../assets/images/home/AIGC4-1.jpeg" alt="">
                <p>VR / AR / MR</p>
              </div>
              <div class="aigcywimg-box1">
                <img class="aigcywimg-tupian" src="../assets/images/home/AIGC5.png" alt="">
                <p>行业 AI 工具</p>
              </div>
              <div class="aigcywimg-box1">
                <img class="aigcywimg-tupian" src="../assets/images/home/AIGC6.png" alt="">
                <p>数字内容生产</p>
              </div>
            </div>
          </div>
          
          
        </div>
      </div>
    </div> -->
        <!-- AIGC业务 -->
    <div class="aigcyw">
      <div class="aigcyw-box">
        <div class="aigcyw-title">
          <h1>AIGC 业务</h1>
          <p>AIGC 是指 AI - Generated Content，人工智能生成内容。内容生产在过去经历了PGC（专业人士创建内容）、UGC（用户创建内容）、AIUGC（用户和 AI 辅助创建），而2024年人工智能技术突飞猛进，AIGC 的大潮已经到来。</p>
        </div>
        <div class="aigcyw-img">
          <div>
            <div class="aigcywimg-box1">
              <div class="aigcywb1">
                <img class="aigcywimg-tupian" src="../assets/images/home/AIGC1-1.png" alt="">
                <p class="aigcywtitle">AIGC 教育</p>
              </div>
              <div>
                <p class="aigcywtitle">AIGC 教育</p>
                <p class="aigcywpp">
                  通过融合人工智能和生成式内容技术形成的新型教育模式，随着技术的不断进步和应用领域的扩大，AIGC 教育将在未来发挥更加重要的作用，为各行各业的创新和发展带来新的机遇和挑战。
                </p> 
              </div>
            </div>

            <div class="aigcywimg-box1">
              <div class="aigcywb1">
                <img class="aigcywimg-tupian" src="../assets/images/home/AIGC2.png" alt="">
                <p class="aigcywtitle">AI 模型训练</p> 
              </div>
              <div>
                <p class="aigcywtitle">AI 模型训练</p> 
                <p class="aigcywpp">
                  AI 模型训练可以用于实际任务中，如图像识别、语音识别、自然语言处理等。通过不断的训练和优化，AI 模型可以在各种应用中发挥越来越重要的作用，推动人工智能技术的发展和应用。
                </p>
              </div>
            </div>

            <div class="aigcywimg-box1">
              <div class="aigcywb1">
                <img class="aigcywimg-tupian" src="../assets/images/home/AIGC3.png" alt="">
                <p class="aigcywtitle">数字人创建</p>
              </div>
              <div>
                <p class="aigcywtitle">数字人创建</p>
                <p class="aigcywpp">
                在数字人创建中骨骼绑定是关键步骤之一，还可以赋予数字人逼真的动作和姿态。除了视觉呈现，通过集成人工智能技术，数字人能够识别用户的语音和姿态，并作出相应的回应，这种智能交互使得数字人能够与用户建立更紧密的互动关系，从而提升用户体验。
                </p>
              </div>
            </div>
          </div>

          <div>
            <div class="aigcywimg-box1">
              <div class="aigcywb1">
                <img class="aigcywimg-tupian" src="../assets/images/home/AIGC4-1.jpeg" alt="">
                <p class="aigcywtitle">VR / AR / MR</p>
              </div>
              <div>
                <p class="aigcywtitle">VR / AR / MR</p>
                <p class="aigcywpp">
                VR（虚拟现实）、AR（增强现实）和MR（混合现实）这三种技术不断发展和融合，将为人们带来更加真实、丰富和沉浸式的数字世界体验。
                </p>
              </div>
            </div>

            <div class="aigcywimg-box1">
              <div class="aigcywb1">
                <img class="aigcywimg-tupian" src="../assets/images/home/AIGC5.png" alt="">
                <p class="aigcywtitle">行业 AI 工具</p>
              </div>
              <div>
                <p class="aigcywtitle">行业 AI 工具</p>
                <p class="aigcywpp">
                AI 工具不仅可以提高各行各业的业务效率，降低运营成本，还可以为企业带来新的商业模式和增长点。这些AI 工具利用机器学习、深度学习等先进技术，结合行业数据和业务需求，为用户提供智能化的解决方案。
                </p>
              </div>
            </div>

            <div class="aigcywimg-box1">
              <div class="aigcywb1">
                <img class="aigcywimg-tupian" src="../assets/images/home/AIGC6.png" alt="">
                <p class="aigcywtitle">数字内容生产</p>
              </div>
              <div>
                <p class="aigcywtitle">数字内容生产</p>
                <p class="aigcywpp">
                随着数字技术的不断发展和用户需求的不断变化，将创意转化为具体的数字内容具有高效性、灵活性、互动性、多样性和可量化分析等优势，能够满足大环境下用户多样化的信息需求和审美偏好，推动数字产业的快速发展。
                </p>
              </div>
              
            </div>
          </div>


        </div>
      </div>
    </div>
    <!-- 成功案例 -->
    <div class="succeed">
        <div class="succeed-box">
            <div class="succeed-title">
                <h1>成功案例</h1>
            </div>
            <div class="succeed-content">
               <div class="anli">
                    <img src="../assets/images/business/su1.png" alt="">
                    <div class="succeed-txex">
                        <div class="center">
                            <p>数字人</p>
                        </div>
                        <div class="liaojiebutton">
                            <div class="liaojie"></div>
                            <p class="liaojie-txt" @click="goDigitalhuman">查看更多</p>
                        </div>
                    </div>
                </div> 
                <div class="anli">
                    <img src="../assets/images/business/su2.png" alt="">
                    <div class="succeed-txex">
                        <div class="center">
                            <p>数字化生产</p>
                        </div>
                        <div class="liaojiebutton2">
                            <div class="liaojie2"></div>
                            <p class="liaojie-txt2" @click="goDigitalhuicif">查看更多</p>
                        </div>
                    </div>
                </div> 
                <div class="anli">
                    <img src="../assets/images/business/su3.png" alt="">
                    <div class="succeed-txex">
                        <div class="center">
                            <p>元宇宙空间</p>
                        </div>
                        <div class="liaojiebutton3">
                            <div class="liaojie3"></div>
                            <p class="liaojie-txt3" @click="toHengshaguan">查看更多</p>
                        </div>
                    </div>
                </div> 
            </div>
            
        </div>
    </div>
    </div>
</template>
<script>
import HeaderNav from "@/components/HeaderNav.vue"

export default{
    name:"BusinessView",
    components:{
        HeaderNav
    },
    data(){
        return{

        }
    },
    methods:{
        //跳转数字人页面
      goDigitalhuman() {
      this.$router.push({
        // 跳转到的页面路径
        path: "/digitalhuman",
        // 传递的参数集合
        query: {},
      });
      window.scrollTo({
        top: 0,
        // behavior: 'smooth' 
        }); 
    }, 
     //跳恒沙数字馆页面
    toHengshaguan() {
      window.open("http://show.hengshadigit.com/home","_blank")
      
    },
     //跳转数字文博页面
      goDigitalhuicif() {
      this.$router.push({
        // 跳转到的页面路径
        path: "/digitalhuicif",
        // 传递的参数集合
        query: {},
      });
      window.scrollTo({
        top: 0,
        // behavior: 'smooth' 
        });
    }, 
    }
}
</script>

<style scoped>
.body {
  width: 100%;
  height: 100%;
}
/* AIGC业务 */
.aigcyw{
  width: 100%;
  height: 1000px;
  position: relative;
  margin: 0 auto;
  background-color: rgba(238, 118, 55, 0.1);
}
.aigcyw-box{
  width: 1200px;
  height: 1000px;
  position: absolute;
  /* background-color: rgb(121, 77, 20); */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.aigcyw-title{
  margin-top: 100px;
}
.aigcyw-img{
  margin-top: 50px;
  display: flex;

}
.aigcywimage1 img{
width: 450px;
}
.aigcywimgdb1{
  display: flex;
  margin-bottom: 50px;
}
.aigcyw-title h1{
  text-align: center;
}
.aigcyw-title p{
  text-indent: 2em;
  line-height: 30px;
}
.aigcywimg-tupian{
  width: 150px;
}
.aigcywimg-box1{
  width: 550px;
  height: 200px;
  background-color: #f7f7f7;
  margin-left: 42px;
  margin-top: 15px;
  display: flex;
}
.aigcywb1{
  background-color: #ffffff;
}
.aigcywtitle{
  text-align: center;
  font-size: 18px;
  font-weight: 700;
}
.aigcywpp{
  margin-left: 10px;
  width: 380px;
  font-size: 16px;
  text-indent: 2em;
}
.aigcywimg-box1 img{
  width: 150px;
  height: 113.97px;
}
/* 成功案例 */
.succeed{
  width: 100%;
  height: 800px;
  position: relative;
  margin: 0 auto;
  background-color: #ffffff;
}
.succeed-box{
    width: 1200px;
  height: 800px;
  position: absolute;
  /* background-color: rgb(121, 77, 20); */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.succeed-title{
    text-align: center;
    margin-top: 50px;
}
.anli{
    margin-top: 100px;
    margin-left: 75px;
}
.anli img{
    width: 300px;
    height: 300px;
}
.succeed-txex{
    display: flex;
}
.center p{
font-size: 20px;
font-weight: 700;
/* margin-bottom: 10px; */
}
.liaojiebutton{
    display: flex;
    margin-top: 10px;
}
.liaojie{
width: 100px;
  height: 40px;
  background-color: rgb(238, 118, 55);
  position: relative;
  margin-left: 140px;
  
}
.liaojie-txt{
position: absolute;
top: 515px;
left: 290px;
font-size: 18px;
font-weight: 700;
color: #ffffff;
cursor: pointer;
}
.succeed-content{
    display: flex;
}
.liaojiebutton2{
    display: flex;
    margin-top: 10px;
}
.liaojie2{
width: 100px;
  height: 40px;
  background-color: rgb(238, 118, 55);
  position: relative;
  margin-left: 100px;
  
}
.liaojie-txt2{
position: absolute;
top: 515px;
left: 670px;
font-size: 18px;
font-weight: 700;
color: #ffffff;
cursor: pointer;
}
.liaojiebutton3{
    display: flex;
    margin-top: 10px;
}
.liaojie3{
width: 100px;
  height: 40px;
  background-color: rgb(238, 118, 55);
  position: relative;
  margin-left: 100px;
  
}
.liaojie-txt3{
position: absolute;
top: 515px;
left: 1040px;
font-size: 18px;
font-weight: 700;
color: #ffffff;
cursor: pointer;
}
</style>
