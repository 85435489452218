export default{
    namespaced:true,
    state: {
        //登录表单
        isshowLogin:false
    },
    getters: {
    },
    mutations: {
        chanisshowLogin(state,payload){
            state.isshowLogin = payload
        }
    },
    actions: {
    },
}